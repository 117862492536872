var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"media-info"},[(_vm.employeesMovementMediaData.mediaTypeToken == 'MET-1')?_c('img',{attrs:{"src":_vm.medidaSrc,"onerror":"this.src='https://api-premco-v1.adminssw.com//_DefaultFiles/employee.png'","alt":"Image"}}):_vm._e(),(_vm.employeesMovementMediaData.mediaTypeToken == 'MET-2')?_c('video',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.medidaSrc,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e()]),_c('div',{staticClass:"row mt-2"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeesMovementMediaData.movementMediaNameAr,"title":_vm.$t('nameAr'),"imgName":'media.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeesMovementMediaData.movementMediaNameEn,"title":_vm.$t('nameEn'),"imgName":'media.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeesMovementMediaData.movementMediaDescriptionAr,"title":_vm.$t('descriptionAr'),"imgName":'info.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.employeesMovementMediaData.movementMediaDescriptionEn,"title":_vm.$t('descriptionEn'),"imgName":'info.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeesMovementMediaData.addedByUserNameAr,
          _vm.employeesMovementMediaData.addedByUserNameEn
        ),"title":_vm.$t('userMakeAction'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.setDataMultiLang(
          _vm.language,
          _vm.employeesMovementMediaData.movementTypeNameAr,
          _vm.employeesMovementMediaData.movementTypeNameEn
        ),"title":_vm.$t('movementTypeName'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.employeesMovementMediaData.movementMediaNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }