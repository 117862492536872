<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <BranchRoleForm
      v-if="!isLoading"
      :branchRoleData="branchRoleData"
      :submitName="$t('edit')"
      v-on:addOrUpdateBranchRole="addOrUpdateBranchRole"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import BranchRoleForm from "@/components/settings/settingsOther/branchRoles/BranchRoleForm.vue";
import BranchRole from "@/models/settings/settingsOther/branchRoles/BranchRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "BranchRoleEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    BranchRoleForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      branchRoleData: new BranchRole(),
      branchRoleToken: this.$route.params.branchRoleToken,
    };
  },
  methods: {
    async getBranchRoleDetails() {
      this.isLoading = true;
      this.branchRoleData.branchRoleToken = this.branchRoleToken;
      try {
        let response = await this.branchRoleData.getBranchRoleDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.branchRoleData.fillData(response.data.branchRoleData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.branchRoleData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.branchRoleData = null;
      }
      this.isLoading = false;
    },
    async addOrUpdateBranchRole() {
      this.isLoading = true;
      try {
        let response = await this.branchRoleData.addOrUpdateBranchRole(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push("/branch-roles");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getBranchRoleDetails();
  },
};
</script>

<style scoped lang="scss"></style>
