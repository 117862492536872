<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateStoreRole()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'storeRoleNameAr'"
            :value="storeRoleData.storeRoleNameAr"
            v-on:changeValue="storeRoleData.storeRoleNameAr = $event"
            :title="$t('setting.others.storeRolesMsgs.nameAr')"
            :imgName="'store-roles.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'storeRoleNameEn'"
            :value="storeRoleData.storeRoleNameEn"
            v-on:changeValue="storeRoleData.storeRoleNameEn = $event"
            :title="$t('setting.others.storeRolesMsgs.nameEn')"
            :imgName="'store-roles.svg'"
          />
          <CustomInput
            :className="'col-md-12'"
            :id="'storeRoleNameUnd'"
            :value="storeRoleData.storeRoleNameUnd"
            v-on:changeValue="storeRoleData.storeRoleNameUnd = $event"
            :title="$t('setting.others.storeRolesMsgs.nameUnd')"
            :imgName="'store-roles.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'storeRoleDescriptionAr'"
            :value="storeRoleData.storeRoleDescriptionAr"
            v-on:changeValue="storeRoleData.storeRoleDescriptionAr = $event"
            :title="$t('setting.others.storeRolesMsgs.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'storeRoleDescriptionEn'"
            :value="storeRoleData.storeRoleDescriptionEn"
            v-on:changeValue="storeRoleData.storeRoleDescriptionEn = $event"
            :title="$t('setting.others.storeRolesMsgs.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'storeRoleDescriptionUnd'"
            :value="storeRoleData.storeRoleDescriptionUnd"
            v-on:changeValue="storeRoleData.storeRoleDescriptionUnd = $event"
            :title="$t('setting.others.storeRolesMsgs.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'storeRoleNotes'"
            :value="storeRoleData.storeRoleNotes"
            v-on:changeValue="storeRoleData.storeRoleNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/store-roles" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "StoreRoleForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["storeRoleData", "submitName"],
  methods: {
    async addOrUpdateStoreRole() {
      this.$emit("addOrUpdateStoreRole");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
