export default class UsersPrivilegeEmployeeOfficialHolidaysPlans {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeOfficialHolidaysPlansView = false;
    this.employeeOfficialHolidaysPlansAdd = false;
    this.employeeOfficialHolidaysPlansUpdate = false;
    this.employeeOfficialHolidaysPlansArchive = false;
    this.employeeOfficialHolidaysPlansRestore = false;
    this.employeeOfficialHolidaysPlansDelete = false;
  }
  fillData(data) {
    this.employeeOfficialHolidaysPlansView =
      data.employeeOfficialHolidaysPlansView;
    this.employeeOfficialHolidaysPlansAdd =
      data.employeeOfficialHolidaysPlansAdd;
    this.employeeOfficialHolidaysPlansUpdate =
      data.employeeOfficialHolidaysPlansUpdate;
    this.employeeOfficialHolidaysPlansArchive =
      data.employeeOfficialHolidaysPlansArchive;
    this.employeeOfficialHolidaysPlansRestore =
      data.employeeOfficialHolidaysPlansRestore;
    this.employeeOfficialHolidaysPlansDelete =
      data.employeeOfficialHolidaysPlansDelete;
  }
}
