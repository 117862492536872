var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings-salary",modifiers:{"settings-salary":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("settings-salary"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"settings-salary"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
            ),expression:"\n              usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeSalarySettingRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
            )?_vm._i(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeSalarySetting, "employeeSalarySettingRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeSalarySettingRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }