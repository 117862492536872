export default class UsersPrivilegeEmployeesMovement {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesMovementView = false;
    this.employeesMovementViewSelf = false;
    this.employeesMovementViewAll = false;
    this.employeesMovementUserMoveSelf = false;
    this.employeesMovementAddManual = false;
    this.employeesMovementUpdate = false;
    this.employeesMovementDelete = false;
  }
  fillData(data) {
    this.employeesMovementView = data.employeesMovementView;
    this.employeesMovementViewSelf = data.employeesMovementViewSelf;
    this.employeesMovementViewAll = data.employeesMovementViewAll;
    this.employeesMovementUserMoveSelf = data.employeesMovementUserMoveSelf;
    this.employeesMovementAddManual = data.employeesMovementAddManual;
    this.employeesMovementUpdate = data.employeesMovementUpdate;
    this.employeesMovementDelete = data.employeesMovementDelete;
  }
}
