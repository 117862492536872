import { render, staticRenderFns } from "./MedicineDelete.vue?vue&type=template&id=c192b41c&scoped=true&"
import script from "./MedicineDelete.vue?vue&type=script&lang=js&"
export * from "./MedicineDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c192b41c",
  null
  
)

export default component.exports