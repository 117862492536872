var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"EmployeeSalarySettingInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/money.svg")}}),_vm._v(" "+_vm._s(_vm.$t("employeeSalarySettingData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeNameCurrent,"title":_vm.$t('employeeName'),"imgName":'man.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.timeToLang(_vm.employeeSalarySettingData.actionDateTime),"title":_vm.$t('actionDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeSalarySettingNameCurrent,"title":_vm.$t('salarySettingName'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeSalarySettingActivationStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeSalarySettingDescriptionCurrent,"title":_vm.$t('employeeSalarySettingDescription'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeSalarySettingNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeBasicSalary + _vm.$t('EGP'),"title":_vm.$t('employeeBasicSalary'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeVariableSalary + _vm.$t('EGP'),"title":_vm.$t('employeeVariableSalary'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeTotalSalary + _vm.$t('EGP'),"title":_vm.$t('employeeTotalSalary'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeWorkDaysNumberPerMonth,"title":_vm.$t('employeeWorkDaysNumberPerMonth'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeWorkHoursNumberPerDay,"title":_vm.$t('employeeWorkHoursNumberPerDay'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.employeeSalarySettingData.employeeWorkHourPrice
          ? _vm.employeeSalarySettingData.employeeWorkHourPrice + _vm.$t('EGP')
          : _vm.$t('notFound'),"title":_vm.$t('employeeWorkHourPrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedAttendLateInDayByHours24,"title":_vm.$t('maximumAllowedAttendLateInDay'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedAttendLateInDayStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.$t('days') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedAttendLateInMonthByDays +
        ' ) / ' +
        _vm.$t('hours') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedAttendLateInMonthByHours24 +
        ' )',"title":_vm.$t('maximumAllowedAttendLateInMonth'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedAttendLateInMonthStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedEarlyDepartureInDayByHours24,"title":_vm.$t('maximumAllowedEarlyDepartureInDay'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedEarlyDepartureInDayStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.$t('days') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedEarlyDepartureInMonthByDays +
        ' ) / ' +
        _vm.$t('hours') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedEarlyDepartureInMonthByHours24 +
        ' )',"title":_vm.$t('maximumAllowedEarlyDepartureInMonth'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedEarlyDepartureInMonthStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInDayByHours24,"title":_vm.$t('maximumAllowedOverTimeInDay'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInDayStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.$t('days') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByDays +
        ' ) / ' +
        _vm.$t('hours') +
        ': ( ' +
        _vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByHours24 +
        ' )',"title":_vm.$t('maximumAllowedOverTimeInMonth'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthByDays,"title":_vm.$t('maximumAllowedOverTimeInMonth'),"imgName":'hours.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedOverTimeInMonthStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedShiftAbsenceUntillSalaryStop,"title":_vm.$t('maximumAllowedShiftAbsenceUntillSalaryStop'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMaximumAllowedShiftAbsenceUntillSalaryStopStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeDeductionFactorValueCaseLate,"title":_vm.$t('employeeDeductionFactorValueCaseLate'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeDeductionPriceCaseLateStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeDeductionFactorValueCaseEarlyDeparture,"title":_vm.$t('employeeDeductionFactorValueCaseEarlyDeparture'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeDeductionFactorValueCaseEarlyDepartureStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeBalancePlanNameCurrent,"title":_vm.$t('balancesPlansName'),"imgName":'balances-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeAbsencePlanNameCurrent,"title":_vm.$t('absencePlansName'),"imgName":'absence-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeAllowancePlanNameCurrent,"title":_vm.$t('allowancesPlansName'),"imgName":'allowances-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeMonthAllowancesPlanNameCurrent,"title":_vm.$t('monthAllowancesPlansName'),"imgName":'month-allowances-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeOverTimePlanNameCurrent,"title":_vm.$t('overTimePlansName'),"imgName":'overtime-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.employeeSalarySettingData.employeeSalarySettingNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }