var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.addOrUpdateMonthAllowancesPlansSlidesDetails.apply(null, arguments)}}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.allowanceTokenOptions},model:{value:(_vm.monthAllowancesPlansSlideDetailsData.allowanceToken),callback:function ($$v) {_vm.$set(_vm.monthAllowancesPlansSlideDetailsData, "allowanceToken", $$v)},expression:"monthAllowancesPlansSlideDetailsData.allowanceToken"}}),_c('img',{attrs:{"src":require("@/assets/images/allowances.svg")}})],1),_c('CustomInput',{attrs:{"className":'col-md-6',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][monthAllowancesValue]"),"value":_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesValue,"title":_vm.$t('allowanceValue'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesValue = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][monthAllowancesMaximumNumberInMonth]"),"value":_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesMaximumNumberInMonth,"title":_vm.$t('allowancesMaximumNumberInMonth'),"imgName":'date.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesMaximumNumberInMonth =
              $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][monthAllowancesMaximumNumberInYear]"),"value":_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesMaximumNumberInYear,"title":_vm.$t('allowancesMaximumNumberInYear'),"imgName":'year.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesMaximumNumberInYear =
              $event}}}),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("beginningOfTheEntitlement")))]),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-3',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][beginningOfTheEntitlementInDays]"),"value":_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInDays,"title":_vm.$t('day'),"imgName":'days.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInDays =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][beginningOfTheEntitlementInHours]"),"value":_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInHours,"title":_vm.$t('hour'),"imgName":'hours.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInHours =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][beginningOfTheEntitlementInMinutes]"),"value":_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInMinutes,"title":_vm.$t('minute'),"imgName":'minutes.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInMinutes =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][beginningOfTheEntitlementInSeconds]"),"value":_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInSeconds,"title":_vm.$t('second'),"imgName":'seconds.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.beginningOfTheEntitlementInSeconds =
                  $event}}})],1)]),_c('TextArea',{attrs:{"className":'col-md-12',"id":("monthAllowancesPlansSlidesDetailsData[" + _vm.index + "][monthAllowancesPlansSlidesDetailsNote]"),"value":_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesPlansSlidesDetailsNote =
              $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":("/month-allowances-plan-details/" + (_vm.monthAllowancesPlansSlideDetailsData.monthAllowancesPlansToken))}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }