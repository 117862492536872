var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.addOrUpdateInsurancesPlansSlidesDetails.apply(null, arguments)}}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6"},[_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.planTypeTokenOptions},model:{value:(_vm.insurancesPlansSlideDetailsData.planTypeToken),callback:function ($$v) {_vm.$set(_vm.insurancesPlansSlideDetailsData, "planTypeToken", $$v)},expression:"insurancesPlansSlideDetailsData.planTypeToken"}}),_c('img',{attrs:{"src":require("@/assets/images/insurances.svg")}})],1),_c('TextArea',{attrs:{"className":'col-md-6',"id":'insurancesPlansSlidesDetailsNote',"value":_vm.insurancesPlansSlideDetailsData.insurancesPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.insurancesPlansSlidesDetailsNote =
              $event}}}),_c('div',{staticClass:"my-card col-md-12"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeBasicSalaryFrom',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryFrom,"title":_vm.$t('employeeBasicSalaryFrom'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryFrom =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeBasicSalaryTo',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryTo,"title":_vm.$t('employeeBasicSalaryTo'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryTo = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeBasicSalaryPercentage',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryPercentage,"title":_vm.$t('employeeBasicSalaryPercentage'),"imgName":'percentage.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryPercentage =
                  $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryDeductionStatus,"title":_vm.$t('employeeBasicSalaryDeductionStatus')},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryDeductionStatus =
                  $event}}})],1)]),_c('div',{staticClass:"my-card col-md-12"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeVariableSalaryFrom',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryFrom,"title":_vm.$t('employeeVariableSalaryFrom'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryFrom =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeVariableSalaryTo',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryTo,"title":_vm.$t('employeeVariableSalaryTo'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryTo =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeVariableSalaryPercentage',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryPercentage,"title":_vm.$t('employeeVariableSalaryPercentage'),"imgName":'percentage.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryPercentage =
                  $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryDeductionStatus,"title":_vm.$t('employeeVariableSalaryDeductionStatus')},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryDeductionStatus =
                  $event}}})],1)]),_c('div',{staticClass:"my-card col-md-12"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":parseFloat(
                  _vm.insurancesPlansSlideDetailsData.employeeBasicSalaryFrom
                    ? _vm.insurancesPlansSlideDetailsData.employeeBasicSalaryFrom
                    : 0
                ) +
                parseFloat(
                  _vm.insurancesPlansSlideDetailsData.employeeVariableSalaryFrom
                    ? _vm.insurancesPlansSlideDetailsData.employeeVariableSalaryFrom
                    : 0
                ),"title":_vm.$t('employeeTotalSalaryFrom'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":parseFloat(
                  _vm.insurancesPlansSlideDetailsData.employeeBasicSalaryTo
                    ? _vm.insurancesPlansSlideDetailsData.employeeBasicSalaryTo
                    : 0
                ) +
                parseFloat(
                  _vm.insurancesPlansSlideDetailsData.employeeVariableSalaryTo
                    ? _vm.insurancesPlansSlideDetailsData.employeeVariableSalaryTo
                    : 0
                ),"title":_vm.$t('employeeTotalSalaryTo'),"imgName":'money.svg'}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":'employeeTotalSalaryPercentage',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryPercentage,"title":_vm.$t('employeeTotalSalaryPercentage'),"imgName":'percentage.svg'},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryPercentage =
                  $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryDeductionStatus,"title":_vm.$t('employeeTotalSalaryDeductionStatus')},on:{"changeValue":function($event){_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryDeductionStatus =
                  $event}}})],1)])],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":("/insurances-plan-details/" + (_vm.insurancesPlansSlideDetailsData.insurancesPlansToken))}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }