<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="storeRolesData">
      <StoreRolesTable
        :storeRolesData="storeRolesData"
        :storeRoleData="storeRoleData"
        v-on:setStoreRoleData="storeRoleData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="filterStoreRoleData.paginationData"
        v-on:changePage="changePage"
      />
      <StoreRoleInfo :storeRoleData="storeRoleData" />
      <StoreRoleDelete
        :storeRoleData="storeRoleData"
        v-on:refresh="getAllStoreRoles()"
      />
    </div>

    <StoreRoleFloatBtns
      :filterStoreRoleData="filterStoreRoleData"
      v-on:search="getAllStoreRoles()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import StoreRolesTable from "@/components/settings/settingsOther/storeRoles/StoreRolesTable.vue";
import StoreRoleInfo from "@/components/settings/settingsOther/storeRoles/StoreRoleInfo.vue";
import StoreRoleDelete from "@/components/settings/settingsOther/storeRoles/StoreRoleDelete.vue";
import StoreRoleFloatBtns from "@/components/settings/settingsOther/storeRoles/StoreRoleFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import StoreRole from "@/models/settings/settingsOther/storeRoles/StoreRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "StoreRoles",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    StoreRolesTable,
    StoreRoleInfo,
    StoreRoleDelete,
    StoreRoleFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      storeRolesData: null,
      storeRoleData: new StoreRole(),
      filterStoreRoleData: new StoreRole(),
    };
  },
  methods: {
    changePage(page) {
      this.filterStoreRoleData.paginationData.selfPage = page;
      this.getAllStoreRoles();
    },
    refresh() {
      this.storeRolesData = null;
      this.getAllStoreRoles();
    },
    async getAllStoreRoles() {
      this.isLoading = true;
      try {
        let response = await this.filterStoreRoleData.getAllStoreRoles(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.storeRolesData =
            response.data.storeRolesPagination.storeRolesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.storeRolesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.storeRolesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.storeRolesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.storeRolesData = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllStoreRoles();
  },
};
</script>
