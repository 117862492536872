export default class UsersPrivilegeEmployeeSalarySetting {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeSalarySettingView = false;
    this.employeeSalarySettingAdd = false;
    this.employeeSalarySettingUpdate = false;
    this.employeeSalarySettingArchive = false;
    this.employeeSalarySettingRestore = false;
    this.employeeSalarySettingDelete = false;
  }
  fillData(data) {
    this.employeeSalarySettingView = data.employeeSalarySettingView;
    this.employeeSalarySettingAdd = data.employeeSalarySettingAdd;
    this.employeeSalarySettingUpdate = data.employeeSalarySettingUpdate;
    this.employeeSalarySettingArchive = data.employeeSalarySettingArchive;
    this.employeeSalarySettingRestore = data.employeeSalarySettingRestore;
    this.employeeSalarySettingDelete = data.employeeSalarySettingDelete;
  }
}
