import Vue from "vue";
import VueRouter from "vue-router";
import { ifAuthenticated, ifNotAuthenticated } from "../utils/functions";

import Test from "../views/user/Test.vue";
import Home from "../views/home/Home.vue";
import Login from "../views/user/Login.vue";
// import ForgetPassword from "../views/user/ForgetPassword.vue";

import Employees from "../views/employees/employees/Employees.vue";
import EmployeeAdd from "../views/employees/employees/EmployeeAdd.vue";
import EmployeeEdit from "../views/employees/employees/EmployeeEdit.vue";

import EmployeeBranches from "../views/employees/employeeBranches/EmployeeBranches.vue";
import EmployeeBranchAdd from "../views/employees/employeeBranches/EmployeeBranchAdd.vue";
import EmployeeBranchEdit from "../views/employees/employeeBranches/EmployeeBranchEdit.vue";

import EmployeeSalarySettings from "../views/employees/employeeSalarySettings/EmployeeSalarySettings.vue";
import EmployeeSalarySettingAdd from "../views/employees/employeeSalarySettings/EmployeeSalarySettingAdd.vue";
import EmployeeSalarySettingEdit from "../views/employees/employeeSalarySettings/EmployeeSalarySettingEdit.vue";

import EmployeeWorkSchedules from "../views/employees/employeeWorkSchedules/EmployeeWorkSchedules.vue";
import EmployeeWorkScheduleAdd from "../views/employees/employeeWorkSchedules/EmployeeWorkScheduleAdd.vue";

import EmployeeOfficialHolidaysPlans from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlans.vue";
import EmployeeOfficialHolidaysPlanAdd from "../views/employees/employeeOfficialHolidaysPlans/EmployeeOfficialHolidaysPlanAdd.vue";

import EmployeesInputSheetsAttendance from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetsAttendance.vue";
import EmployeesInputSheetAttendanceAdd from "../views/employees/employeesInputSheetsAttendance/EmployeesInputSheetAttendanceAdd.vue";

import EmployeesInputSheetAttendanceMerges from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMerges.vue";
import EmployeesInputSheetAttendanceMergeAdd from "../views/employees/employeesInputSheetAttendanceMerges/EmployeesInputSheetAttendanceMergeAdd.vue";

import EmployeeAttendance from "../views/employeesAttendance/EmployeeAttendance.vue";
import EmployeeAttendanceAdd from "../views/employeesAttendance/EmployeeAttendanceAdd.vue";
import EmployeeAttendanceEdit from "../views/employeesAttendance/EmployeeAttendanceEdit.vue";

import EmployeeMovement from "../views/employeesMovement/EmployeeMovement.vue";
import EmployeeMovementAdd from "../views/employeesMovement/EmployeeMovementAdd.vue";
import EmployeeMovementEdit from "../views/employeesMovement/EmployeeMovementEdit.vue";

import EmployeeMovementMedia from "../views/employeesMovementMedia/EmployeeMovementMedia.vue";
import EmployeeMovementMediaInfo from "../views/employeesMovementMedia/EmployeeMovementMediaInfo.vue";

import AttendanceHistory from "../views/attendance/AttendanceHistory.vue";
import AttendanceAttend from "../views/attendance/AttendanceAttend.vue";
import AttendanceDeparture from "../views/attendance/AttendanceDeparture.vue";

import Points from "../views/points/Points.vue";
import EmployeePoints from "../views/points/EmployeePoints.vue";

import EmployeeSlices from "../views/slices/EmployeeSlices.vue";
import EmployeeSlicesAdd from "../views/slices/EmployeeSlicesAdd.vue";

import Reports from "../views/reports/Reports.vue";

import EstablishmentPrivileges from "../views/privileges/establishmentPrivileges/EstablishmentPrivileges.vue";

import Branches from "../views/branches/Branches.vue";
import BranchAdd from "../views/branches/BranchAdd.vue";
import BranchEdit from "../views/branches/BranchEdit.vue";

import BranchesTimes from "../views/branchesTimes/BranchesTimes.vue";
import BranchTimeAdd from "../views/branchesTimes/BranchTimeAdd.vue";
import BranchTimeEdit from "../views/branchesTimes/BranchTimeEdit.vue";

import Stores from "../views/stores/Stores.vue";
import StoreAdd from "../views/stores/StoreAdd.vue";
import StoreEdit from "../views/stores/StoreEdit.vue";

import StoresTimes from "../views/storesTimes/StoresTimes.vue";
import StoreTimeAdd from "../views/storesTimes/StoreTimeAdd.vue";
import StoreTimeEdit from "../views/storesTimes/StoreTimeEdit.vue";

import Treasuries from "../views/finance/treasuries/Treasuries.vue";
import TreasuryAdd from "../views/finance/treasuries/TreasuryAdd.vue";
import TreasuryEdit from "../views/finance/treasuries/TreasuryEdit.vue";

import ServicesReservations from "../views/servicesReservations/ServicesReservations.vue";
import ServicesReservationAdd from "../views/servicesReservations/ServicesReservationAdd.vue";

import ServicesPayments from "../views/servicesPayments/ServicesPayments.vue";

import ServicesReservationTreatmentPlans from "../views/servicesReservationTreatmentPlans/ServicesReservationTreatmentPlans.vue";
import ServicesReservationTreatmentPlanAdd from "../views/servicesReservationTreatmentPlans/ServicesReservationTreatmentPlanAdd.vue";

import News from "../views/news/News.vue";
import NewsAdd from "../views/news/NewsAdd.vue";
import NewsEdit from "../views/news/NewsEdit.vue";
import NewsItem from "../views/news/NewsItem.vue";

import Offers from "../views/offers/Offers.vue";
import OfferAdd from "../views/offers/OfferAdd.vue";
import OfferEdit from "../views/offers/OfferEdit.vue";
import OfferItem from "../views/offers/OfferItem.vue";

import TreasuriesTransactions from "../views/finance/treasuriesTransactions/TreasuriesTransactions.vue";
import TreasuryTransactionAdd from "../views/finance/treasuriesTransactions/TreasuryTransactionAdd.vue";

import Revenues from "../views/finance/revenues/Revenues.vue";
import RevenueAdd from "../views/finance/revenues/RevenueAdd.vue";

import Expenses from "../views/finance/expenses/Expenses.vue";
import ExpenseAdd from "../views/finance/expenses/ExpenseAdd.vue";

import Clients from "../views/clients/Clients.vue";
import ClientAdd from "../views/clients/ClientAdd.vue";
import ClientEdit from "../views/clients/ClientEdit.vue";

import SettingsBranch from "../views/settings/settingsBranch/SettingsBranch.vue";
import SettingsEstablishment from "../views/settings/settingsEstablishment/SettingsEstablishment.vue";

import MonthsEstablishmentSettings from "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettings.vue";
import MonthsEstablishmentSettingAdd from "../views/settings/settingsEstablishment/monthsSettings/MonthsEstablishmentSettingAdd.vue";

import EstablishmentYearSettings from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettings.vue";
import EstablishmentYearSettingAdd from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingAdd.vue";
import EstablishmentYearSettingEdit from "../views/settings/settingsEstablishment/yearSettings/EstablishmentYearSettingEdit.vue";

import SettingsOther from "../views/settings/settingsOther/SettingsOther.vue";

import Sectors from "../views/settings/settingsOther/sectors/Sectors.vue";
import SectorsAdd from "../views/settings/settingsOther/sectors/SectorsAdd.vue";
import SectorsEdit from "../views/settings/settingsOther/sectors/SectorsEdit.vue";

import Jobs from "../views/settings/settingsOther/jobs/Jobs.vue";
import JobsAdd from "../views/settings/settingsOther/jobs/JobsAdd.vue";
import JobsEdit from "../views/settings/settingsOther/jobs/JobsEdit.vue";

import Qualifications from "../views/settings/settingsOther/qualifications/Qualifications.vue";
import QualificationsAdd from "../views/settings/settingsOther/qualifications/QualificationsAdd.vue";
import QualificationsEdit from "../views/settings/settingsOther/qualifications/QualificationsEdit.vue";

import RevenuesTypes from "../views/settings/settingsOther/revenuesTypes/RevenuesTypes.vue";
import RevenueTypeAdd from "../views/settings/settingsOther/revenuesTypes/RevenueTypeAdd.vue";
import RevenueTypeEdit from "../views/settings/settingsOther/revenuesTypes/RevenueTypeEdit.vue";

import ExpensesTypes from "../views/settings/settingsOther/expensesTypes/ExpensesTypes.vue";
import ExpenseTypeAdd from "../views/settings/settingsOther/expensesTypes/ExpenseTypeAdd.vue";
import ExpenseTypeEdit from "../views/settings/settingsOther/expensesTypes/ExpenseTypeEdit.vue";

import ServicesTypes from "../views/settings/settingsOther/servicesTypes/ServicesTypes.vue";
import ServicesTypeAdd from "../views/settings/settingsOther/servicesTypes/ServicesTypeAdd.vue";
import ServiceTypeEdit from "../views/settings/settingsOther/servicesTypes/ServiceTypeEdit.vue";

import GeneralSpecialties from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialties.vue";
import GeneralSpecialtyAdd from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyAdd.vue";
import GeneralSpecialtyEdit from "../views/settings/settingsOther/generalSpecialties/GeneralSpecialtyEdit.vue";

import NewsCategories from "../views/settings/settingsOther/newsCategories/NewsCategories.vue";
import NewsCategoryAdd from "../views/settings/settingsOther/newsCategories/NewsCategoryAdd.vue";
import NewsCategoryEdit from "../views/settings/settingsOther/newsCategories/NewsCategoryEdit.vue";

import SpecialSpecialties from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialties.vue";
import SpecialSpecialtyAdd from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyAdd.vue";
import SpecialSpecialtyEdit from "../views/settings/settingsOther/specialSpecialties/SpecialSpecialtyEdit.vue";

import Services from "../views/settings/settingsOther/services/Services.vue";
import ServiceAdd from "../views/settings/settingsOther/services/ServiceAdd.vue";
import ServiceEdit from "../views/settings/settingsOther/services/ServiceEdit.vue";

import SettingsSalary from "../views/settings/settingsSalary/SettingsSalary.vue";

import WorkShifts from "../views/settings/settingsSalary/workShifts/WorkShifts.vue";
import WorkShiftAdd from "../views/settings/settingsSalary/workShifts/WorkShiftAdd.vue";
import WorkShiftEdit from "../views/settings/settingsSalary/workShifts/WorkShiftEdit.vue";

import WorkPlans from "../views/settings/settingsSalary/workPlans/WorkPlans.vue";
import WorkPlanAdd from "../views/settings/settingsSalary/workPlans/WorkPlanAdd.vue";
import WorkPlanEdit from "../views/settings/settingsSalary/workPlans/WorkPlanEdit.vue";

import WorkPlanDays from "../views/settings/settingsSalary/workPlanDays/WorkPlanDays.vue";

import SalaryEffects from "../views/settings/settingsSalary/salaryEffects/SalaryEffects.vue";
import SalaryEffectAdd from "../views/settings/settingsSalary/salaryEffects/SalaryEffectAdd.vue";
import SalaryEffectEdit from "../views/settings/settingsSalary/salaryEffects/SalaryEffectEdit.vue";

import OfficialHolidays from "../views/settings/settingsSalary/officialHolidays/OfficialHolidays.vue";
import OfficialHolidayAdd from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayAdd.vue";
import OfficialHolidayEdit from "../views/settings/settingsSalary/officialHolidays/OfficialHolidayEdit.vue";

import BalancesPlans from "../views/settings/settingsSalary/balancesPlans/BalancesPlans.vue";
import BalancesPlanAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanAdd.vue";
import BalancesPlanEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanEdit.vue";
import BalancesPlanDetails from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetails.vue";
import BalancesPlanDetailsAdd from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsAdd.vue";
import BalancesPlanDetailsEdit from "../views/settings/settingsSalary/balancesPlans/BalancesPlanDetailsEdit.vue";

import Allowances from "../views/settings/settingsSalary/allowances/Allowances.vue";
import AllowanceAdd from "../views/settings/settingsSalary/allowances/AllowanceAdd.vue";
import AllowanceEdit from "../views/settings/settingsSalary/allowances/AllowanceEdit.vue";

import AllowancesPlans from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlans.vue";
import AllowancesPlanAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanAdd.vue";
import AllowancesPlanEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanEdit.vue";
import AllowancesPlanDetails from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetails.vue";
import AllowancesPlanDetailsAdd from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsAdd.vue";
import AllowancesPlanDetailsEdit from "../views/settings/settingsSalary/allowancesPlans/AllowancesPlanDetailsEdit.vue";

import MonthAllowancesPlans from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlans.vue";
import MonthAllowancesPlanAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanAdd.vue";
import MonthAllowancesPlanEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanEdit.vue";
import MonthAllowancesPlanDetails from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetails.vue";
import MonthAllowancesPlanDetailsAdd from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsAdd.vue";
import MonthAllowancesPlanDetailsEdit from "../views/settings/settingsSalary/monthAllowancesPlans/MonthAllowancesPlanDetailsEdit.vue";

import OverTimePlans from "../views/settings/settingsSalary/overTimePlans/OverTimePlans.vue";
import OverTimePlanAdd from "../views/settings/settingsSalary/overTimePlans/OverTimePlanAdd.vue";
import OverTimePlanEdit from "../views/settings/settingsSalary/overTimePlans/OverTimePlanEdit.vue";
import OverTimePlanDetails from "../views/settings/settingsSalary/overTimePlans/OverTimePlanDetails.vue";

import AbsencePlans from "../views/settings/settingsSalary/absencePlans/AbsencePlans.vue";
import AbsencePlanAdd from "../views/settings/settingsSalary/absencePlans/AbsencePlanAdd.vue";
import AbsencePlanEdit from "../views/settings/settingsSalary/absencePlans/AbsencePlanEdit.vue";
import AbsencePlanDetails from "../views/settings/settingsSalary/absencePlans/AbsencePlanDetails.vue";

import MainColumns from "../views/settings/settingsSalary/mainColumns/MainColumns.vue";
import MainColumnAdd from "../views/settings/settingsSalary/mainColumns/MainColumnAdd.vue";
import MainColumnEdit from "../views/settings/settingsSalary/mainColumns/MainColumnEdit.vue";

import InsurancesPlans from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlans.vue";
import InsurancesPlanAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanAdd.vue";
import InsurancesPlanEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanEdit.vue";
import InsurancesPlanDetails from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetails.vue";
import InsurancesPlanDetailsAdd from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsAdd.vue";
import InsurancesPlanDetailsEdit from "../views/settings/settingsSalary/insurancesPlans/InsurancesPlanDetailsEdit.vue";

import OfficialHolidaysPlans from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlans.vue";
import OfficialHolidaysPlanAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanAdd.vue";
import OfficialHolidaysPlanEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanEdit.vue";
import OfficialHolidaysPlanDetails from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetails.vue";
import OfficialHolidaysPlanDetailsAdd from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsAdd.vue";
import OfficialHolidaysPlanDetailsEdit from "../views/settings/settingsSalary/officialHolidaysPlans/OfficialHolidaysPlanDetailsEdit.vue";

import CalculatePayroll from "../views/settings/settingsSalary/calculatePayroll/CalculatePayroll.vue";

import Buildings from "../views/settings/settingsBranch/buildings/Buildings.vue";
import BuildingAdd from "../views/settings/settingsBranch/buildings/BuildingAdd.vue";
import BuildingEdit from "../views/settings/settingsBranch/buildings/BuildingEdit.vue";

import Floors from "../views/settings/settingsBranch/floors/Floors.vue";
import FloorAdd from "../views/settings/settingsBranch/floors/FloorAdd.vue";
import FloorEdit from "../views/settings/settingsBranch/floors/FloorEdit.vue";

import Chambers from "../views/settings/settingsBranch/chambers/Chambers.vue";
import ChamberAdd from "../views/settings/settingsBranch/chambers/ChamberAdd.vue";
import ChamberEdit from "../views/settings/settingsBranch/chambers/ChamberEdit.vue";

import Offices from "../views/settings/settingsBranch/offices/Offices.vue";
import OfficeAdd from "../views/settings/settingsBranch/offices/OfficeAdd.vue";
import OfficeEdit from "../views/settings/settingsBranch/offices/OfficeEdit.vue";

import OfficesTimes from "../views/settings/settingsBranch/officesTimes/OfficesTimes.vue";
import OfficesTimeAdd from "../views/settings/settingsBranch/officesTimes/OfficesTimeAdd.vue";
import OfficesTimeEdit from "../views/settings/settingsBranch/officesTimes/OfficesTimeEdit.vue";

import MedicinesTypes from "../views/settings/settingsOther/medicinesTypes/MedicinesTypes.vue";
import MedicinesTypeAdd from "../views/settings/settingsOther/medicinesTypes/MedicinesTypeAdd.vue";
import MedicinesTypeEdit from "../views/settings/settingsOther/medicinesTypes/MedicinesTypeEdit.vue";

import Medicines from "../views/settings/settingsOther/medicines/Medicines.vue";
import MedicineAdd from "../views/settings/settingsOther/medicines/MedicineAdd.vue";
import MedicineEdit from "../views/settings/settingsOther/medicines/MedicineEdit.vue";

import Requirements from "../views/settings/settingsOther/requirements/Requirements.vue";
import RequirementAdd from "../views/settings/settingsOther/requirements/RequirementAdd.vue";
import RequirementEdit from "../views/settings/settingsOther/requirements/RequirementEdit.vue";

import BloodTypes from "../views/settings/settingsOther/bloodTypes/BloodTypes.vue";
import BloodTypeAdd from "../views/settings/settingsOther/bloodTypes/BloodTypeAdd.vue";
import BloodTypeEdit from "../views/settings/settingsOther/bloodTypes/BloodTypeEdit.vue";

import MethodPaids from "../views/settings/settingsOther/methodPaids/MethodPaids.vue";
import MethodPaidAdd from "../views/settings/settingsOther/methodPaids/MethodPaidAdd.vue";
import MethodPaidEdit from "../views/settings/settingsOther/methodPaids/MethodPaidEdit.vue";

import ScientificDegrees from "../views/settings/settingsOther/scientificDegrees/ScientificDegrees.vue";
import ScientificDegreeAdd from "../views/settings/settingsOther/scientificDegrees/ScientificDegreeAdd.vue";
import ScientificDegreeEdit from "../views/settings/settingsOther/scientificDegrees/ScientificDegreeEdit.vue";

import Countries from "../views/settings/settingsOther/countries/Countries.vue";
import CountryAdd from "../views/settings/settingsOther/countries/CountryAdd.vue";
import CountryEdit from "../views/settings/settingsOther/countries/CountryEdit.vue";

import Governates from "../views/settings/settingsOther/governates/Governates.vue";
import GovernateAdd from "../views/settings/settingsOther/governates/GovernateAdd.vue";
import GovernateEdit from "../views/settings/settingsOther/governates/GovernateEdit.vue";

import Cities from "../views/settings/settingsOther/cities/Cities.vue";
import CityAdd from "../views/settings/settingsOther/cities/CityAdd.vue";
import CityEdit from "../views/settings/settingsOther/cities/CityEdit.vue";

import Districts from "../views/settings/settingsOther/districts/Districts.vue";
import DistrictAdd from "../views/settings/settingsOther/districts/DistrictAdd.vue";
import DistrictEdit from "../views/settings/settingsOther/districts/DistrictEdit.vue";

import InsuranceCompanies from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanies.vue";
import InsuranceCompanyAdd from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyAdd.vue";
import InsuranceCompanyEdit from "../views/settings/settingsOther/insuranceCompanies/InsuranceCompanyEdit.vue";

import EstablishmentRoles from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoles.vue";
import EstablishmentRoleAdd from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoleAdd.vue";
import EstablishmentRoleEdit from "../views/settings/settingsOther/establishmentRoles/EstablishmentRoleEdit.vue";

import BranchRoles from "../views/settings/settingsOther/branchRoles/BranchRoles.vue";
import BranchRoleAdd from "../views/settings/settingsOther/branchRoles/BranchRoleAdd.vue";
import BranchRoleEdit from "../views/settings/settingsOther/branchRoles/BranchRoleEdit.vue";

import StoreRoles from "../views/settings/settingsOther/storeRoles/StoreRoles.vue";
import StoreRoleAdd from "../views/settings/settingsOther/storeRoles/StoreRoleAdd.vue";
import StoreRoleEdit from "../views/settings/settingsOther/storeRoles/StoreRoleEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: "/forget-password",
  //   name: "ForgetPassword",
  //   component: ForgetPassword,
  //   beforeEnter: ifAuthenticated,
  // },

  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee/:employeeToken",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-branches/:employeeToken?/:branchToken?",
    name: "EmployeeBranches",
    component: EmployeeBranches,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-branch",
    name: "EmployeeBranchAdd",
    component: EmployeeBranchAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee-branch/:employeeBranchesToken",
    name: "EmployeeBranchEdit",
    component: EmployeeBranchEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-salary-settings/:employeeToken",
    name: "EmployeeSalarySettings",
    component: EmployeeSalarySettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-salary-setting",
    name: "EmployeeSalarySettingAdd",
    component: EmployeeSalarySettingAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee-salary-setting/:employeeSalarySettingToken",
    name: "EmployeeSalarySettingEdit",
    component: EmployeeSalarySettingEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-work-schedules/:employeeToken",
    name: "EmployeeWorkSchedules",
    component: EmployeeWorkSchedules,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-work-schedule",
    name: "EmployeeWorkScheduleAdd",
    component: EmployeeWorkScheduleAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-official-holidays-plans/:employeeToken",
    name: "EmployeeOfficialHolidaysPlans",
    component: EmployeeOfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-official-holidays-plan",
    name: "EmployeeOfficialHolidaysPlanAdd",
    component: EmployeeOfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employees-input-sheets-attendance",
    name: "EmployeesInputSheetsAttendance",
    component: EmployeesInputSheetsAttendance,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employees-input-sheet-attendance",
    name: "EmployeesInputSheetAttendanceAdd",
    component: EmployeesInputSheetAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employees-input-sheet-attendance-merges",
    name: "EmployeesInputSheetAttendanceMerges",
    component: EmployeesInputSheetAttendanceMerges,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employees-input-sheet-attendance-merge",
    name: "EmployeesInputSheetAttendanceMergeAdd",
    component: EmployeesInputSheetAttendanceMergeAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-employee/:employeeToken",
    name: "EmployeeAttendance",
    component: EmployeeAttendance,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance-employee/:employeeToken",
    name: "EmployeeAttendanceAdd",
    component: EmployeeAttendanceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-attendance-employee/:employeeToken/:attendanceToken",
    name: "EmployeeAttendanceEdit",
    component: EmployeeAttendanceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/movement-employee/:attendanceToken",
    name: "EmployeeMovement",
    component: EmployeeMovement,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-movement-employee/:attendanceToken",
    name: "EmployeeMovementAdd",
    component: EmployeeMovementAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-movement-employee/:attendanceToken/:movementToken",
    name: "EmployeeMovementEdit",
    component: EmployeeMovementEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-movement-media/:movementToken",
    name: "EmployeeMovementMedia",
    component: EmployeeMovementMedia,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-movement-media-info/:movementMediaToken",
    name: "EmployeeMovementMediaInfo",
    component: EmployeeMovementMediaInfo,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/attendance-history",
    name: "AttendanceHistory",
    component: AttendanceHistory,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-attendance",
    name: "AttendanceAttend",
    component: AttendanceAttend,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-departure",
    name: "AttendanceDeparture",
    component: AttendanceDeparture,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/points",
    name: "Points",
    component: Points,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/employee-points/:employeeToken",
    name: "EmployeePoints",
    component: EmployeePoints,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/employee-slices/:employeeToken",
    name: "EmployeeSlices",
    component: EmployeeSlices,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee-slice/:employeeToken",
    name: "EmployeeSlicesAdd",
    component: EmployeeSlicesAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/establishment-privileges",
    name: "EstablishmentPrivileges",
    component: EstablishmentPrivileges,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-branch",
    name: "BranchAdd",
    component: BranchAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-branch/:branchToken",
    name: "BranchEdit",
    component: BranchEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/branches-times",
    name: "BranchesTimes",
    component: BranchesTimes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-branch-time",
    name: "BranchTimeAdd",
    component: BranchTimeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-branch-time/:branchTimeToken",
    name: "BranchTimeEdit",
    component: BranchTimeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/stores",
    name: "Stores",
    component: Stores,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-store",
    name: "StoreAdd",
    component: StoreAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-store/:storeToken",
    name: "StoreEdit",
    component: StoreEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/stores-times",
    name: "StoresTimes",
    component: StoresTimes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-store-time",
    name: "StoreTimeAdd",
    component: StoreTimeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-store-time/:storeTimeToken",
    name: "StoreTimeEdit",
    component: StoreTimeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/treasuries",
    name: "Treasuries",
    component: Treasuries,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-treasury",
    name: "TreasuryAdd",
    component: TreasuryAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-treasury/:treasuryToken",
    name: "TreasuryEdit",
    component: TreasuryEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services-reservations",
    name: "ServicesReservations",
    component: ServicesReservations,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-services-reservation",
    name: "ServicesReservationAdd",
    component: ServicesReservationAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services-payments/:clientToken?/:reservationToken?",
    name: "ServicesPayments",
    component: ServicesPayments,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/treatments-reservation/:clientToken?/:reservationToken?",
    name: "ServicesReservationTreatmentPlans",
    component: ServicesReservationTreatmentPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-treatments-reservation/:clientToken?/:reservationToken?",
    name: "ServicesReservationTreatmentPlanAdd",
    component: ServicesReservationTreatmentPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/news",
    name: "News",
    component: News,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-news",
    name: "NewsAdd",
    component: NewsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-news/:newsToken",
    name: "NewsEdit",
    component: NewsEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/news-item/:newsToken",
    name: "NewsItem",
    component: NewsItem,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/offers",
    name: "Offers",
    component: Offers,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-offer",
    name: "OfferAdd",
    component: OfferAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-offer/:offerToken",
    name: "OfferEdit",
    component: OfferEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/offer-item/:offerToken",
    name: "OfferItem",
    component: OfferItem,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/treasuries-transactions",
    name: "TreasuriesTransactions",
    component: TreasuriesTransactions,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-treasury-transaction",
    name: "TreasuryTransactionAdd",
    component: TreasuryTransactionAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/revenues",
    name: "Revenues",
    component: Revenues,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-revenue",
    name: "RevenueAdd",
    component: RevenueAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-expense",
    name: "ExpenseAdd",
    component: ExpenseAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-client",
    name: "ClientAdd",
    component: ClientAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-client/:clientToken",
    name: "ClientEdit",
    component: ClientEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-branch",
    name: "SettingsBranch",
    component: SettingsBranch,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-establishment",
    name: "SettingsEstablishment",
    component: SettingsEstablishment,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/months-establishment-settings",
    name: "MonthsEstablishmentSettings",
    component: MonthsEstablishmentSettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-months-establishment-setting",
    name: "MonthsEstablishmentSettingAdd",
    component: MonthsEstablishmentSettingAdd,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/year-establishment-settings",
    name: "EstablishmentYearSettings",
    component: EstablishmentYearSettings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-year-establishment-setting",
    name: "EstablishmentYearSettingAdd",
    component: EstablishmentYearSettingAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-year-establishment-setting/:establishmentYearSettingToken",
    name: "EstablishmentYearSettingEdit",
    component: EstablishmentYearSettingEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-other",
    name: "SettingsOther",
    component: SettingsOther,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/sectors",
    name: "Sectors",
    component: Sectors,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-sector",
    name: "SectorsAdd",
    component: SectorsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-sector/:sectorToken",
    name: "SectorsEdit",
    component: SectorsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-job",
    name: "JobsAdd",
    component: JobsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-job/:jobToken",
    name: "JobsEdit",
    component: JobsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/qualifications",
    name: "Qualifications",
    component: Qualifications,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-qualification",
    name: "QualificationsAdd",
    component: QualificationsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-qualification/:qualificationToken",
    name: "QualificationsEdit",
    component: QualificationsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/revenues-types",
    name: "RevenuesTypes",
    component: RevenuesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-revenue-type",
    name: "RevenueTypeAdd",
    component: RevenueTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-revenue-type/:revenuesTypeToken",
    name: "RevenueTypeEdit",
    component: RevenueTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/expenses-types",
    name: "ExpensesTypes",
    component: ExpensesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-expense-type",
    name: "ExpenseTypeAdd",
    component: ExpenseTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-expense-type/:expensesTypeToken",
    name: "ExpenseTypeEdit",
    component: ExpenseTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services-types",
    name: "ServicesTypes",
    component: ServicesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-services-type",
    name: "ServicesTypeAdd",
    component: ServicesTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-services-type/:serviceTypeToken",
    name: "ServiceTypeEdit",
    component: ServiceTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/general-specialties",
    name: "GeneralSpecialties",
    component: GeneralSpecialties,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-general-specialty",
    name: "GeneralSpecialtyAdd",
    component: GeneralSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-general-specialty/:generalSpecialtyToken",
    name: "GeneralSpecialtyEdit",
    component: GeneralSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/news-categories",
    name: "NewsCategories",
    component: NewsCategories,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-news-category",
    name: "NewsCategoryAdd",
    component: NewsCategoryAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-news-category/:newsCategoryToken",
    name: "NewsCategoryEdit",
    component: NewsCategoryEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/special-specialties",
    name: "SpecialSpecialties",
    component: SpecialSpecialties,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-special-specialty",
    name: "SpecialSpecialtyAdd",
    component: SpecialSpecialtyAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-special-specialty/:specialSpecialtyToken",
    name: "SpecialSpecialtyEdit",
    component: SpecialSpecialtyEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/services",
    name: "Services",
    component: Services,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-service",
    name: "ServiceAdd",
    component: ServiceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-service/:serviceToken",
    name: "ServiceEdit",
    component: ServiceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/settings-salary",
    name: "SettingsSalary",
    component: SettingsSalary,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-shifts",
    name: "WorkShifts",
    component: WorkShifts,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-work-shift",
    name: "WorkShiftAdd",
    component: WorkShiftAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-work-shift/:workShiftToken",
    name: "WorkShiftEdit",
    component: WorkShiftEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-plans",
    name: "WorkPlans",
    component: WorkPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-work-plan",
    name: "WorkPlanAdd",
    component: WorkPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-work-plan/:workPlanToken",
    name: "WorkPlanEdit",
    component: WorkPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/work-plan-days/:workPlanToken",
    name: "WorkPlanDays",
    component: WorkPlanDays,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/salary-effects",
    name: "SalaryEffects",
    component: SalaryEffects,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-salary-effect",
    name: "SalaryEffectAdd",
    component: SalaryEffectAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-salary-effect/:employeeSalaryEffectToken",
    name: "SalaryEffectEdit",
    component: SalaryEffectEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/official-holidays",
    name: "OfficialHolidays",
    component: OfficialHolidays,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holiday",
    name: "OfficialHolidayAdd",
    component: OfficialHolidayAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holiday/:officialHolidayToken",
    name: "OfficialHolidayEdit",
    component: OfficialHolidayEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/balances-plans",
    name: "BalancesPlans",
    component: BalancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-balances-plan",
    name: "BalancesPlanAdd",
    component: BalancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-balances-plan/:balancesPlansToken",
    name: "BalancesPlanEdit",
    component: BalancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetails",
    component: BalancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-balances-plan-details/:balancesPlansToken",
    name: "BalancesPlanDetailsAdd",
    component: BalancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-balances-plan-details/:balancesPlansSlidesDetailsToken",
    name: "BalancesPlanDetailsEdit",
    component: BalancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/allowances",
    name: "Allowances",
    component: Allowances,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowance",
    name: "AllowanceAdd",
    component: AllowanceAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowance/:allowanceToken",
    name: "AllowanceEdit",
    component: AllowanceEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/allowances-plans",
    name: "AllowancesPlans",
    component: AllowancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowances-plan",
    name: "AllowancesPlanAdd",
    component: AllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowances-plan/:allowancesPlansToken",
    name: "AllowancesPlanEdit",
    component: AllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetails",
    component: AllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-allowances-plan-details/:allowancesPlansToken",
    name: "AllowancesPlanDetailsAdd",
    component: AllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-allowances-plan-details/:allowancesPlansSlidesDetailsToken",
    name: "AllowancesPlanDetailsEdit",
    component: AllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/month-allowances-plans",
    name: "MonthAllowancesPlans",
    component: MonthAllowancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-month-allowances-plan",
    name: "MonthAllowancesPlanAdd",
    component: MonthAllowancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-month-allowances-plan/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanEdit",
    component: MonthAllowancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetails",
    component: MonthAllowancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-month-allowances-plan-details/:monthAllowancesPlansToken",
    name: "MonthAllowancesPlanDetailsAdd",
    component: MonthAllowancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-month-allowances-plan-details/:monthAllowancesPlansSlidesDetailsToken",
    name: "MonthAllowancesPlanDetailsEdit",
    component: MonthAllowancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/overtime-plans",
    name: "OverTimePlans",
    component: OverTimePlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-overtime-plan",
    name: "OverTimePlanAdd",
    component: OverTimePlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-overtime-plan/:overTimePlansToken",
    name: "OverTimePlanEdit",
    component: OverTimePlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/overtime-plan-details/:overTimePlansToken",
    name: "OverTimePlanDetails",
    component: OverTimePlanDetails,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/absence-plans",
    name: "AbsencePlans",
    component: AbsencePlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-absence-plan",
    name: "AbsencePlanAdd",
    component: AbsencePlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-absence-plan/:absencePlansToken",
    name: "AbsencePlanEdit",
    component: AbsencePlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/absence-plan-details/:absencePlansToken",
    name: "AbsencePlanDetails",
    component: AbsencePlanDetails,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/main-columns",
    name: "MainColumns",
    component: MainColumns,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-main-column",
    name: "MainColumnAdd",
    component: MainColumnAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-main-column/:mainColumnToken",
    name: "MainColumnEdit",
    component: MainColumnEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/insurances-plans",
    name: "InsurancesPlans",
    component: InsurancesPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-insurances-plan",
    name: "InsurancesPlanAdd",
    component: InsurancesPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-insurances-plan/:insurancesPlansToken",
    name: "InsurancesPlanEdit",
    component: InsurancesPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetails",
    component: InsurancesPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-insurances-plan-details/:insurancesPlansToken",
    name: "InsurancesPlanDetailsAdd",
    component: InsurancesPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-insurances-plan-details/:insurancesPlansSlidesDetailsToken",
    name: "InsurancesPlanDetailsEdit",
    component: InsurancesPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/official-holidays-plans",
    name: "OfficialHolidaysPlans",
    component: OfficialHolidaysPlans,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holidays-plan",
    name: "OfficialHolidaysPlanAdd",
    component: OfficialHolidaysPlanAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holidays-plan/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanEdit",
    component: OfficialHolidaysPlanEdit,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetails",
    component: OfficialHolidaysPlanDetails,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-official-holidays-plan-details/:officialHolidaysPlansToken",
    name: "OfficialHolidaysPlanDetailsAdd",
    component: OfficialHolidaysPlanDetailsAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-official-holidays-plan-details/:officialHolidaysPlansSlidesDetailsToken",
    name: "OfficialHolidaysPlanDetailsEdit",
    component: OfficialHolidaysPlanDetailsEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/calculate-payroll",
    name: "CalculatePayroll",
    component: CalculatePayroll,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/buildings",
    name: "Buildings",
    component: Buildings,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-building",
    name: "BuildingAdd",
    component: BuildingAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-building/:buildingToken",
    name: "BuildingEdit",
    component: BuildingEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/floors",
    name: "Floors",
    component: Floors,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-floor",
    name: "FloorAdd",
    component: FloorAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-floor/:floorToken",
    name: "FloorEdit",
    component: FloorEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/chambers",
    name: "Chambers",
    component: Chambers,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-chamber",
    name: "ChamberAdd",
    component: ChamberAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-chamber/:chamberToken",
    name: "ChamberEdit",
    component: ChamberEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/offices",
    name: "Offices",
    component: Offices,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-office",
    name: "OfficeAdd",
    component: OfficeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-office/:officeToken",
    name: "OfficeEdit",
    component: OfficeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/offices-times",
    name: "OfficesTimes",
    component: OfficesTimes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-offices-time",
    name: "OfficesTimeAdd",
    component: OfficesTimeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-offices-time/:officeTimeToken",
    name: "OfficesTimeEdit",
    component: OfficesTimeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/medicines-types",
    name: "MedicinesTypes",
    component: MedicinesTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-medicines-type",
    name: "MedicinesTypeAdd",
    component: MedicinesTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-medicines-type/:medicinesTypeToken",
    name: "MedicinesTypeEdit",
    component: MedicinesTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/medicines",
    name: "Medicines",
    component: Medicines,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-medicine",
    name: "MedicineAdd",
    component: MedicineAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-medicine/:medicineToken",
    name: "MedicineEdit",
    component: MedicineEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/requirements",
    name: "Requirements",
    component: Requirements,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-requirement",
    name: "RequirementAdd",
    component: RequirementAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-requirement/:requirementToken",
    name: "RequirementEdit",
    component: RequirementEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/blood-types",
    name: "BloodTypes",
    component: BloodTypes,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-blood-type",
    name: "BloodTypeAdd",
    component: BloodTypeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-blood-type/:bloodTypeToken",
    name: "BloodTypeEdit",
    component: BloodTypeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/method-paids",
    name: "MethodPaids",
    component: MethodPaids,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-method-paid",
    name: "MethodPaidAdd",
    component: MethodPaidAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-method-paid/:methodPaidToken",
    name: "MethodPaidEdit",
    component: MethodPaidEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/scientific-degrees",
    name: "scientificDegrees",
    component: ScientificDegrees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-scientific-degree",
    name: "scientificDegreeAdd",
    component: ScientificDegreeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-scientific-degree/:scientificDegreeToken",
    name: "scientificDegreeEdit",
    component: ScientificDegreeEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/countries",
    name: "Countries",
    component: Countries,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-country",
    name: "CountryAdd",
    component: CountryAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-country/:countryToken",
    name: "CountryEdit",
    component: CountryEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/governates",
    name: "Governates",
    component: Governates,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-governate",
    name: "GovernateAdd",
    component: GovernateAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-governate/:governateToken",
    name: "GovernateEdit",
    component: GovernateEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/cities",
    name: "Cities",
    component: Cities,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-city",
    name: "CityAdd",
    component: CityAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-city/:cityToken",
    name: "CityEdit",
    component: CityEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/districts",
    name: "Districts",
    component: Districts,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-district",
    name: "DistrictAdd",
    component: DistrictAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-district/:districtToken",
    name: "DistrictEdit",
    component: DistrictEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/insurance-companies",
    name: "InsuranceCompanies",
    component: InsuranceCompanies,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-insurance-company",
    name: "InsuranceCompanyAdd",
    component: InsuranceCompanyAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-insurance-company/:insuranceCompanyToken",
    name: "InsuranceCompanyEdit",
    component: InsuranceCompanyEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/establishment-roles",
    name: "EstablishmentRoles",
    component: EstablishmentRoles,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-establishment-role",
    name: "EstablishmentRoleAdd",
    component: EstablishmentRoleAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-establishment-role/:establishmentRoleToken",
    name: "EstablishmentRoleEdit",
    component: EstablishmentRoleEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/branch-roles",
    name: "BranchRoles",
    component: BranchRoles,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-branch-role",
    name: "BranchRoleAdd",
    component: BranchRoleAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-branch-role/:branchRoleToken",
    name: "BranchRoleEdit",
    component: BranchRoleEdit,
    beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/store-roles",
    name: "StoreRoles",
    component: StoreRoles,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-store-role",
    name: "StoreRoleAdd",
    component: StoreRoleAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-store-role/:storeRoleToken",
    name: "StoreRoleEdit",
    component: StoreRoleEdit,
    beforeEnter: ifNotAuthenticated,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
