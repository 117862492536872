<template>
  <div class="">
    <b-button v-b-toggle.settings-salary class="btn btn-lg btn-collapse">
      {{ $t("settings-salary") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="settings-salary">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingView"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingAdd"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingUpdate"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingUpdate
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingArchive"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingArchive
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingDelete"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingDelete
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeSalarySettingRestore"
              v-model="
                usersPrivilegeEmployeeSalarySetting.employeeSalarySettingRestore
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeSalarySettingRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeEmployeeSalarySetting",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeeSalarySetting"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
