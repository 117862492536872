<template>
  <div class="">
    <b-button v-b-toggle.officialHolidaysPlans class="btn btn-lg btn-collapse">
      {{ $t("officialHolidaysPlans") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="officialHolidaysPlans">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansView"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeOfficialHolidaysPlansView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansAdd"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeOfficialHolidaysPlansAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansUpdate"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="employeeOfficialHolidaysPlansUpdate"
              >{{ $t("edit") }}</label
            >
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansArchive"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="employeeOfficialHolidaysPlansArchive"
              >{{ $t("archive") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansDelete"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="employeeOfficialHolidaysPlansDelete"
              >{{ $t("finalDelete") }}</label
            >
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeOfficialHolidaysPlansRestore"
              v-model="
                usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
              "
              class="checkbox"
            />
            <label
              class="mt-1 ml-2"
              for="employeeOfficialHolidaysPlansRestore"
              >{{ $t("restore") }}</label
            >
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeEmployeeOfficialHolidaysPlans",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeeOfficialHolidaysPlans"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
