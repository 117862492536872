export const EMPLOYEE_TYPE = {
  Employee: "EMT-1",
  Admin: "EMT-2",
  FounderOfSystem: "EMT-3",
  Receptionist: "EMT-4",
  Doctor: "EMT-5",
};

export const RESERVATION_STATUS = {
  pending: "RTT-1", // Reservations pending الحجوزات المعلقة
  current: "RTT-2", // Current reservations الحجوزات الحالية
  waitingApprovalCancel: "RTT-3", // Waiting Approval Cancel في انتظار التأكيد علي إلغاء الحجز
  canceledBeforeApproval: "RTT-4", // Canceled Before Approval الحجوزات الملغية قبل التأكيد علي الحجز
  canceledAfterApproval: "RTT-5", // Canceled After Approval الحجوزات الملغية بعد التأكيد علي الحجز
  attended: "RTT-6", // Attend To center حضر بالمركز
  entered: "RTT-7", // Service Reservations الحجوزات بالخدمة
  waitingReEntry: "RTT-8", // Reservations awaiting re-entry الحجوزات المنتظرة الدخول مره اخري
  finished: "RTT-9", // Finish reservations الحجوزات المنتهية
};
