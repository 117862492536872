import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";

export default class EstablishmentRole {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRoleToken = "";
    this.establishmentRoleNameCurrent = "";
    this.establishmentRoleNameEn = "";
    this.establishmentRoleNameAr = "";
    this.establishmentRoleNameUnd = "";
    this.roleTypeToken = "RTT-1";
    this.roleTypeNameEn = "";
    this.roleTypeNameAr = "";
    this.roleTypeNameUnd = "";
    this.roleTypeNameCurrent = "";
    this.establishmentRoleDescriptionCurrent = "";
    this.establishmentRoleDescriptionEn = "";
    this.establishmentRoleDescriptionAr = "";
    this.establishmentRoleDescriptionUnd = "";
    this.establishmentRoleNotes = "";
    this.establishmentRoleArchiveStatus = "";
    this.paginationData = new PaginationData();
    this.textSearch = "";
  }
  fillData(data) {
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.establishmentRoleNameCurrent = data.establishmentRoleNameCurrent;
    this.establishmentRoleNameEn = data.establishmentRoleNameEn;
    this.establishmentRoleNameAr = data.establishmentRoleNameAr;
    this.establishmentRoleNameUnd = data.establishmentRoleNameUnd;
    this.roleTypeToken = data.roleTypeToken;
    this.roleTypeNameEn = data.roleTypeNameEn;
    this.roleTypeNameAr = data.roleTypeNameAr;
    this.roleTypeNameUnd = data.roleTypeNameUnd;
    this.roleTypeNameCurrent = data.roleTypeNameCurrent;
    this.establishmentRoleDescriptionCurrent =
      data.establishmentRoleDescriptionCurrent;
    this.establishmentRoleDescriptionEn = data.establishmentRoleDescriptionEn;
    this.establishmentRoleDescriptionAr = data.establishmentRoleDescriptionAr;
    this.establishmentRoleDescriptionUnd = data.establishmentRoleDescriptionUnd;
    this.establishmentRoleNotes = data.establishmentRoleNotes;
    this.establishmentRoleArchiveStatus = data.establishmentRoleArchiveStatus;
  }

  async getAllEstablishmentRoles(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetAllEstablishmentRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${this.paginationData.selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${this.textSearch}`
      );
      this.paginationData = {
        totalPages: response.data.EstablishmentRolesPagination.totalPages,
        totalItems: response.data.EstablishmentRolesPagination.totalItems,
        countItemsInPage:
          response.data.EstablishmentRolesPagination.countItemsInPage,
        selfPage: response.data.EstablishmentRolesPagination.selfPage,
      };
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEstablishmentRoleDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetEstablishmentRoleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRoleToken=${this.establishmentRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEstablishmentRoles(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EstablishmentRoles/GetDialogOfEstablishmentRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEstablishmentRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
      establishmentRoleNameAr: this.establishmentRoleNameAr,
      establishmentRoleNameUnd: this.establishmentRoleNameUnd,
      roleNameUnd: this.roleNameUnd,
      roleTypeToken: this.roleTypeToken,
      establishmentRoleDescriptionEn: this.establishmentRoleDescriptionEn,
      establishmentRoleDescriptionAr: this.establishmentRoleDescriptionAr,
      establishmentRoleDescriptionUnd: this.establishmentRoleDescriptionUnd,
      establishmentRoleNotes: this.establishmentRoleNotes,
    };

    try {
      let response = "";
      if (
        this.establishmentRoleToken == "" ||
        this.establishmentRoleToken == undefined
      ) {
        response = await axios.post(
          `/EstablishmentRoles/AddEstablishmentRole`,
          data
        );
      } else {
        response = await axios.post(
          `/EstablishmentRoles/UpdateEstablishmentRole`,
          data
        );
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEstablishmentRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
    };

    try {
      let response = await axios.post(
        `/EstablishmentRoles/ArchiveEstablishmentRole`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
