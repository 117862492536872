import { render, staticRenderFns } from "./ScientificDegrees.vue?vue&type=template&id=6a548e71&"
import script from "./ScientificDegrees.vue?vue&type=script&lang=js&"
export * from "./ScientificDegrees.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports