var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.officialHolidaysPlans",modifiers:{"officialHolidaysPlans":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("officialHolidaysPlans"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"officialHolidaysPlans"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
            ),expression:"\n              usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeeOfficialHolidaysPlansRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
            )?_vm._i(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
            ,null)>-1:(
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeeOfficialHolidaysPlans.employeeOfficialHolidaysPlansRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeeOfficialHolidaysPlans, "employeeOfficialHolidaysPlansRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeeOfficialHolidaysPlansRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }