import { render, staticRenderFns } from "./UsersPrivilegeEmployeesAttendance.vue?vue&type=template&id=5969cd12&scoped=true&"
import script from "./UsersPrivilegeEmployeesAttendance.vue?vue&type=script&lang=js&"
export * from "./UsersPrivilegeEmployeesAttendance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5969cd12",
  null
  
)

export default component.exports