<template>
  <div class="">
    <b-button v-b-toggle.EmployeeWorkSchedule class="btn btn-lg btn-collapse">
      {{ $t("EmployeeWorkSchedule") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="EmployeeWorkSchedule">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleView"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleView
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleAdd"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleAdd
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleUpdate"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleUpdate
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleArchive"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleArchive
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleDelete"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleDelete
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeWorkScheduleRestore"
              v-model="
                usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleRestore
              "
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeWorkScheduleRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeEmployeeWorkSchedule",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeeWorkSchedule"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
