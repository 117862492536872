<template>
  <div class="">
    <b-button v-b-toggle.employee-branches class="btn btn-lg btn-collapse">
      {{ $t("employee-branches") }}
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </b-button>
    <b-collapse id="employee-branches">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesView"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesView"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesView">{{
              $t("view")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesAdd"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesAdd"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesAdd">{{
              $t("add")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesUpdate"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesUpdate"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesUpdate">{{
              $t("edit")
            }}</label>
          </div>

          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesArchive"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesArchive"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesArchive">{{
              $t("archive")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesDelete"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesDelete"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesDelete">{{
              $t("finalDelete")
            }}</label>
          </div>
          <div class="form-label-group col-md-6 col-lg-4">
            <input
              type="checkbox"
              id="employeeBranchesRestore"
              v-model="usersPrivilegeEmployeeBranches.employeeBranchesRestore"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="employeeBranchesRestore">{{
              $t("restore")
            }}</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "UsersPrivilegeEmployeeBranches",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["usersPrivilegeEmployeeBranches"],
  methods: {},
};
</script>

<style scoped lang="scss"></style>
