var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeesAttendance",modifiers:{"usersPrivilegeEmployeesAttendance":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("attendance"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeesAttendance"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceView
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceView
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceViewAll"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewAll", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewAll", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewAll", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceViewAll"}},[_vm._v(_vm._s(_vm.$t("viewAttendanceHistoryForAll")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceViewSelf"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceViewSelf
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewSelf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewSelf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceViewSelf", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceViewSelf"}},[_vm._v(_vm._s(_vm.$t("viewAttendanceHistoryForSelf")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceAttendanceList"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceList
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceList", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceList", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceList", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceAttendanceList"}},[_vm._v(_vm._s(_vm.$t("addAttendanceForAll")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceAttendedSelf"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendedSelf
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendedSelf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendedSelf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendedSelf", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceAttendedSelf"}},[_vm._v(_vm._s(_vm.$t("addAttendanceForSelf")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceDepatureList"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepatureList
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepatureList", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepatureList", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepatureList", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceDepatureList"}},[_vm._v(_vm._s(_vm.$t("addDepartureForAll")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceDepartureSelf"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDepartureSelf
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepartureSelf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepartureSelf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDepartureSelf", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceDepartureSelf"}},[_vm._v(_vm._s(_vm.$t("addDepartureForSelf")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceAttendanceManual"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceAttendanceManual
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceManual", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceManual", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceAttendanceManual", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceAttendanceManual"}},[_vm._v(_vm._s(_vm.$t("addAttendanceHistoryForUser")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceUpdate"}},[_vm._v(_vm._s(_vm.$t("updateAttendanceHistoryForUser")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDelete
            ),expression:"\n              usersPrivilegeEmployeesAttendance.employeesAttendanceDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesAttendanceDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesAttendance.employeesAttendanceDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesAttendance, "employeesAttendanceDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesAttendanceDelete"}},[_vm._v(_vm._s(_vm.$t("deleteAttendanceHistoryForUser")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }