var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"InsurancesPlanDetailsInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/insurances-plans.svg")}}),_vm._v(" "+_vm._s(_vm.$t("insurancesPlansSlideDetailsData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.insurancesPlansNameCurrent,"title":_vm.$t('insurancesPlansName'),"imgName":'insurances-plans.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.planTypeNameCurrent,"title":_vm.$t('planTypeName'),"imgName":'insurances.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryFrom,"title":_vm.$t('employeeBasicSalaryFrom'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryTo,"title":_vm.$t('employeeBasicSalaryTo'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryPercentage,"title":_vm.$t('employeeBasicSalaryPercentage'),"imgName":'percentage.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeBasicSalaryDeductionStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('employeeBasicSalaryDeductionStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryFrom,"title":_vm.$t('employeeVariableSalaryFrom'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryTo,"title":_vm.$t('employeeVariableSalaryTo'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryPercentage,"title":_vm.$t('employeeVariableSalaryPercentage'),"imgName":'percentage.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeVariableSalaryDeductionStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('employeeVariableSalaryDeductionStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryFrom,"title":_vm.$t('employeeTotalSalaryFrom'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryTo,"title":_vm.$t('employeeTotalSalaryTo'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryPercentage,"title":_vm.$t('employeeTotalSalaryPercentage'),"imgName":'percentage.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.insurancesPlansSlideDetailsData.employeeTotalSalaryDeductionStatus ===
        true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('employeeTotalSalaryDeductionStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-12',"value":_vm.insurancesPlansSlideDetailsData.insurancesPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }