var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.addOrUpdateBalancesPlansSlidesDetails.apply(null, arguments)}}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-12"},[_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.employeeSalaryEffectTokenOptions},model:{value:(_vm.balancesPlansSlideDetailsData.employeeSalaryEffectToken),callback:function ($$v) {_vm.$set(_vm.balancesPlansSlideDetailsData, "employeeSalaryEffectToken", $$v)},expression:"balancesPlansSlideDetailsData.employeeSalaryEffectToken"}}),_c('img',{attrs:{"src":require("@/assets/images/money.svg")}})],1),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("maximumNumberInDay")))]),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-4',"id":'maximumNumberInDayInHours',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInDayInHours,"title":_vm.$t('hour'),"imgName":'hours.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInDayInHours =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-4',"id":'maximumNumberInDayInMinutes',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInDayInMinutes,"title":_vm.$t('minute'),"imgName":'minutes.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInDayInMinutes =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-4',"id":'maximumNumberInDayInSeconds',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInDayInSeconds,"title":_vm.$t('second'),"imgName":'seconds.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInDayInSeconds =
                  $event}}})],1)]),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("maximumNumberInMonth")))]),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInMonthInDays',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInDays,"title":_vm.$t('day'),"imgName":'days.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInDays =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInMonthInHours',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInHours,"title":_vm.$t('hour'),"imgName":'hours.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInHours =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInMonthInMinutes',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInMinutes,"title":_vm.$t('minute'),"imgName":'minutes.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInMinutes =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInMonthInSeconds',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInSeconds,"title":_vm.$t('second'),"imgName":'seconds.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInMonthInSeconds =
                  $event}}})],1)]),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("maximumNumberInYear")))]),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInYearInDays',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInYearInDays,"title":_vm.$t('day'),"imgName":'days.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInYearInDays =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInYearInHours',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInYearInHours,"title":_vm.$t('hour'),"imgName":'hours.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInYearInHours =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInYearInMinutes',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInYearInMinutes,"title":_vm.$t('minute'),"imgName":'minutes.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInYearInMinutes =
                  $event}}}),_c('CustomInput',{attrs:{"className":'col-md-3',"id":'maximumNumberInYearInSeconds',"value":_vm.balancesPlansSlideDetailsData.maximumNumberInYearInSeconds,"title":_vm.$t('second'),"imgName":'seconds.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.maximumNumberInYearInSeconds =
                  $event}}})],1)]),_c('TextArea',{attrs:{"className":'col-md-12',"id":'balancesPlansSlidesDetailsNote',"value":_vm.balancesPlansSlideDetailsData.balancesPlansSlidesDetailsNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.balancesPlansSlideDetailsData.balancesPlansSlidesDetailsNote =
              $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":("/balances-plan-details/" + (_vm.balancesPlansSlideDetailsData.balancesPlansToken))}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }