import { checkLoginForShowSidebar } from "@/utils/functions";

export default {
  state: {
    userData: null,
    employeePersonalData: null,
    userPersonalData: null,
    usersEstablishmentPrivilegeData: null,
    employeeBranchPrivilage: null,
    employeeStorePrivilage: null,
    constantsLists: null,
    usersVersionData: null,
  },
  getters: {
    userData: (state) => state.userData,
    employeePersonalData: (state) => state.employeePersonalData,
    userPersonalData: (state) => state.userPersonalData,
    usersEstablishmentPrivilegeData: (state) =>
      state.usersEstablishmentPrivilegeData,
    employeeBranchPrivilage: (state) => state.employeeBranchPrivilage,
    employeeStorePrivilage: (state) => state.employeeStorePrivilage,
    constantsLists: (state) => state.constantsLists,
    usersVersionData: (state) => state.usersVersionData,
  },
  mutations: {
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
      state.employeePersonalData = userData.employeePersonalData;
      state.userPersonalData = userData.userPersonalData;
      state.usersEstablishmentPrivilegeData =
        userData.usersEstablishmentPrivilegeData;
      state.employeeBranchPrivilage = userData.employeeBranchPrivilage;
      state.employeeStorePrivilage = userData.employeeStorePrivilage;
      state.constantsLists = userData.constantLists;
      state.usersVersionData = userData.usersVersionData;
      checkLoginForShowSidebar();
    },
    LOGOUT_USER: (state) => {
      state.userData = null;
      state.employeePersonalData = null;
      state.userPersonalData = null;
      state.usersEstablishmentPrivilegeData = null;
      state.employeeBranchPrivilage = null;
      state.employeeStorePrivilage = null;
      state.constantsLists = null;
      state.usersVersionData = null;
      checkLoginForShowSidebar();
    },
  },
  actions: {
    logoutUser(context) {
      context.commit("LOGOUT_USER");
    },
    updateUserData(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
  },
  modules: {},
};
