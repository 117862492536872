var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"MainColumnInfo","scrollable":"","size":"lg","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/main-columns.svg")}}),_vm._v(" "+_vm._s(_vm.$t("mainColumnData"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.mainColumnNameAr,"title":_vm.$t('mainColumnNameAr'),"imgName":'main-columns.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.mainColumnNameEn,"title":_vm.$t('mainColumnNameEn'),"imgName":'main-columns.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.mainColumnNameUnd,"title":_vm.$t('mainColumnNameUnd'),"imgName":'main-columns.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.mainColumnNote,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.employeeCodeInOutColumnName,"title":_vm.$t('employeeCodeInOutColumnName'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.employeeCodeInOutFindStatus === true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.branchCodeInOutColumnName,"title":_vm.$t('branchCodeInOutColumnName'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.branchCodeInOutFindStatus === true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.dateTimeInColumnName,"title":_vm.$t('dateTimeInColumnName'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.dateTimeInFindStatus === true ? _vm.$t('yes') : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.dateInColumnName,"title":_vm.$t('dateInColumnName'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.timeInColumnName,"title":_vm.$t('timeInColumnName'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.dateTimeSeparatedInFindStatus === true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.dateTimeOutColumnName,"title":_vm.$t('dateTimeOutColumnName'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-6',"value":_vm.mainColumnData.dateTimeOutFindStatus === true ? _vm.$t('yes') : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.dateOutColumnName,"title":_vm.$t('dateOutColumnName'),"imgName":'date.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.timeOutColumnName,"title":_vm.$t('timeOutColumnName'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-lg-4',"value":_vm.mainColumnData.dateTimeSeparatedOutFindStatus === true
          ? _vm.$t('yes')
          : _vm.$t('no'),"title":_vm.$t('activationStatus'),"imgName":'type.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }