import { render, staticRenderFns } from "./SpecialSpecialtyDelete.vue?vue&type=template&id=5936e510&scoped=true&"
import script from "./SpecialSpecialtyDelete.vue?vue&type=script&lang=js&"
export * from "./SpecialSpecialtyDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5936e510",
  null
  
)

export default component.exports