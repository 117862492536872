import axios from "axios";

import UsersPrivilegeEmployees from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployees";
import UsersPrivilegeEmployeesAttendance from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeesAttendance";
import UsersPrivilegeEmployeesMovement from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeesMovement";
import UsersPrivilegeEmployeesMovementMedai from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeesMovementMedai";
import UsersPrivilegeEstablishmentsSettings from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEstablishmentsSettings"; // change Done
import UsersPrivilegeEmployeesSettlementMovement from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeesSettlementMovement";
import UsersPrivilegAdditionalSettings from "@/models/privileges/establishmentPrivileges/UsersPrivilegAdditionalSettings";
import UsersPrivilegeEmployeesMoveSlides from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeesMoveSlides";
import UsersPrivilegeBranches from "@/models/privileges/establishmentPrivileges/UsersPrivilegeBranches";
import UsersPrivilegeStores from "@/models/privileges/establishmentPrivileges/UsersPrivilegeStores"; //
import UsersPrivilegeClients from "@/models/privileges/establishmentPrivileges/UsersPrivilegeClients"; //
import UsersPrivilegeNews from "@/models/privileges/establishmentPrivileges/UsersPrivilegeNews"; //
import UsersPrivilegeEmployeeSalarySetting from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeeSalarySetting"; // create Done
import UsersPrivilegeEmployeeWorkSchedule from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeeWorkSchedule"; // create Done
import UsersPrivilegeEmployeeBranches from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeeBranches"; // create Done
import UsersPrivilegeEmployeeStores from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeeStores"; //
import UsersPrivilegeContractors from "@/models/privileges/establishmentPrivileges/UsersPrivilegeContractors"; //
import UsersPrivilegeEmployeeOfficialHolidaysPlans from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEmployeeOfficialHolidaysPlans"; // create Done
import UsersPrivilegeTransactionPointsHistory from "@/models/privileges/establishmentPrivileges/UsersPrivilegeTransactionPointsHistory"; //
import UsersPrivilegeOffers from "@/models/privileges/establishmentPrivileges/UsersPrivilegeOffers"; //
import UsersPrivilegeEstablishmentYearSetting from "@/models/privileges/establishmentPrivileges/UsersPrivilegeEstablishmentYearSetting"; // create Done
import UsersPrivilegeUsers from "@/models/privileges/establishmentPrivileges/UsersPrivilegeUsers";

export default class UsersEstablishmentPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.establishmentRoleToken = "";
    this.userEstablishmentPrivilegeToken = "";
    this.usersPrivilegeEmployees = new UsersPrivilegeEmployees();
    this.usersPrivilegeEmployeesAttendance =
      new UsersPrivilegeEmployeesAttendance();
    this.usersPrivilegeEmployeesMovement =
      new UsersPrivilegeEmployeesMovement();
    this.usersPrivilegeEmployeesMovementMedai =
      new UsersPrivilegeEmployeesMovementMedai();
    this.usersPrivilegeEstablishmentsSettings =
      new UsersPrivilegeEstablishmentsSettings();
    this.usersPrivilegeEmployeesSettlementMovement =
      new UsersPrivilegeEmployeesSettlementMovement();
    this.usersPrivilegAdditionalSettings =
      new UsersPrivilegAdditionalSettings();
    this.usersPrivilegeEmployeesMoveSlides =
      new UsersPrivilegeEmployeesMoveSlides();
    this.usersPrivilegeBranches = new UsersPrivilegeBranches();
    this.usersPrivilegeStores = new UsersPrivilegeStores();
    this.usersPrivilegeClients = new UsersPrivilegeClients();
    this.usersPrivilegeNews = new UsersPrivilegeNews();
    this.usersPrivilegeEmployeeSalarySetting =
      new UsersPrivilegeEmployeeSalarySetting();
    this.usersPrivilegeEmployeeWorkSchedule =
      new UsersPrivilegeEmployeeWorkSchedule();
    this.usersPrivilegeEmployeeBranches = new UsersPrivilegeEmployeeBranches();
    this.usersPrivilegeEmployeeStores = new UsersPrivilegeEmployeeStores();
    this.usersPrivilegeContractors = new UsersPrivilegeContractors();
    this.usersPrivilegeEmployeeOfficialHolidaysPlans =
      new UsersPrivilegeEmployeeOfficialHolidaysPlans();
    this.usersPrivilegeTransactionPointsHistory =
      new UsersPrivilegeTransactionPointsHistory();
    this.usersPrivilegeOffers = new UsersPrivilegeOffers();
    this.usersPrivilegeEstablishmentYearSetting =
      new UsersPrivilegeEstablishmentYearSetting();
    this.usersPrivilegeUsers = new UsersPrivilegeUsers();
  }
  fillData(data) {
    this.establishmentRoleToken = data.establishmentRoleToken;
    this.userEstablishmentPrivilegeToken = data.userEstablishmentPrivilegeToken;
    this.usersPrivilegeEmployees = data.usersPrivilegeEmployees;
    this.usersPrivilegeEmployeesAttendance =
      data.usersPrivilegeEmployeesAttendance;
    this.usersPrivilegeEmployeesMovement = data.usersPrivilegeEmployeesMovement;
    this.usersPrivilegeEmployeesMovementMedai =
      data.usersPrivilegeEmployeesMovementMedai;
    this.usersPrivilegeEstablishmentsSettings =
      data.usersPrivilegeEstablishmentsSettings;
    this.usersPrivilegeEmployeesSettlementMovement =
      data.usersPrivilegeEmployeesSettlementMovement;
    this.usersPrivilegAdditionalSettings = data.usersPrivilegAdditionalSettings;
    this.usersPrivilegeEmployeesMoveSlides =
      data.usersPrivilegeEmployeesMoveSlides;
    this.usersPrivilegeBranches = data.usersPrivilegeBranches;
    this.usersPrivilegeStores = data.usersPrivilegeStores;
    this.usersPrivilegeClients = data.usersPrivilegeClients;
    this.usersPrivilegeNews = data.usersPrivilegeNews;
    this.usersPrivilegeEmployeeSalarySetting =
      data.usersPrivilegeEmployeeSalarySetting;
    this.usersPrivilegeEmployeeWorkSchedule =
      data.usersPrivilegeEmployeeWorkSchedule;
    this.usersPrivilegeEmployeeBranches = data.usersPrivilegeEmployeeBranches;
    this.usersPrivilegeEmployeeStores = data.usersPrivilegeEmployeeStores;
    this.usersPrivilegeContractors = data.usersPrivilegeContractors;
    this.usersPrivilegeEmployeeOfficialHolidaysPlans =
      data.usersPrivilegeEmployeeOfficialHolidaysPlans;
    this.usersPrivilegeTransactionPointsHistory =
      data.usersPrivilegeTransactionPointsHistory;
    this.usersPrivilegeOffers = data.usersPrivilegeOffers;
    this.usersPrivilegeEstablishmentYearSetting =
      data.usersPrivilegeEstablishmentYearSetting;
    this.usersPrivilegeUsers = data.usersPrivilegeUsers;
  }

  async getUserEstablishmentPrivilege(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/UsersEstablishmentPrivilege//GetUserEstablishmentPrivilege?language=${language}&userAuthorizeToken=${userAuthorizeToken}&establishmentRoleToken=${this.establishmentRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateUserEstablishmentPrivilege(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      establishmentRoleToken: this.establishmentRoleToken,
      usersPrivilegeEmployees: this.usersPrivilegeEmployees,
      usersPrivilegeEmployeesAttendance: this.usersPrivilegeEmployeesAttendance,
      usersPrivilegeEmployeesMovement: this.usersPrivilegeEmployeesMovement,
      usersPrivilegeEmployeesMovementMedai:
        this.usersPrivilegeEmployeesMovementMedai,
      usersPrivilegeEstablishmentsSettings:
        this.usersPrivilegeEstablishmentsSettings,
      usersPrivilegeEmployeesSettlementMovement:
        this.usersPrivilegeEmployeesSettlementMovement,
      usersPrivilegAdditionalSettings: this.usersPrivilegAdditionalSettings,
      usersPrivilegeEmployeesMoveSlides: this.usersPrivilegeEmployeesMoveSlides,
      usersPrivilegeBranches: this.usersPrivilegeBranches,
      usersPrivilegeStores: this.usersPrivilegeStores,
      usersPrivilegeClients: this.usersPrivilegeClients,
      usersPrivilegeNews: this.usersPrivilegeNews,
      usersPrivilegeEmployeeSalarySetting:
        this.usersPrivilegeEmployeeSalarySetting,
      usersPrivilegeEmployeeWorkSchedule:
        this.usersPrivilegeEmployeeWorkSchedule,
      usersPrivilegeEmployeeBranches: this.usersPrivilegeEmployeeBranches,
      usersPrivilegeEmployeeStores: this.usersPrivilegeEmployeeStores,
      usersPrivilegeContractors: this.usersPrivilegeContractors,
      usersPrivilegeEmployeeOfficialHolidaysPlans:
        this.usersPrivilegeEmployeeOfficialHolidaysPlans,
      usersPrivilegeTransactionPointsHistory:
        this.usersPrivilegeTransactionPointsHistory,
      usersPrivilegeOffers: this.usersPrivilegeOffers,
      usersPrivilegeEstablishmentYearSetting:
        this.usersPrivilegeEstablishmentYearSetting,
      usersPrivilegeUsers: this.usersPrivilegeUsers,
    };

    try {
      let response = await axios.post(
        `/UsersEstablishmentPrivilege//UpdateUserEstablishmentPrivilege`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
