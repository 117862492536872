<template>
  <div>
    <h1>Test</h1>

    <!-- <button @click="reset()" class="btn btn-primary m-2">reset</button>
    <button @click="selectFirst()" class="btn btn-primary m-2">
      Select first
    </button> -->

    <!-- <model-select :options="options" v-model="item" placeholder="Select item">
    </model-select> -->

    <CustomSelectBox
      :className="'col-md-6'"
      :id="'employees'"
      :value="item"
      :title="$t('selectEmployee')"
      :options="options"
      :imgName="'employees.svg'"
      v-on:changeValue="item = $event"
    />
  </div>
</template>

<script>
// import { ModelSelect } from "vue-search-select";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import Car from "@/utils/Car.js";
export default {
  name: "Test",
  data() {
    return {
      phNo: "",
      options: [
        { value: "", text: "Select an item" },
        { value: "1", text: "Mohamed" },
        { value: "2", text: "Ahmed" },
        { value: "3", text: "Ibrahim" },
        { value: "4", text: "Mohand" },
        { value: "5", text: "Marim" },
      ],
      item: "",
    };
  },
  components: {
    // ModelSelect,
    CustomSelectBox,
  },
  methods: {
    // reset() {
    //   this.item = {};
    // },
    // selectFirst() {
    //   this.item = this.options[0];
    // },
  },
  created() {
    let car1 = new Car("Toyota", "Red");
    // let car1 = new Car();
    // console.log(Car.prototype.getDetails());
    console.log(car1.getDetails());
  },
};
</script>
