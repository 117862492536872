import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import ServicesReservationPay from "@/models/servicesPayments/ServicesReservationPay";

export default class ServicesReservation {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationToken = "";
    this.reservationCode = "";
    this.clientToken = "";
    this.clientNameCurrent = "";
    this.insuranceCompanyToken = "";
    this.insuranceCompanyNameCurrent = "";
    this.branchToken = "";
    this.branchNameCurrent = "";
    this.buildingToken = "";
    this.floorToken = "";
    this.chamberToken = "";
    this.officeToken = "";
    this.officeNameCurrent = "";
    this.officeTimeToken = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.timeNameCurrent = "";
    this.timeNameEn = "";
    this.timeNameAr = "";
    this.timeNameUnd = "";
    this.officeOpenTime = "";
    this.officeCloseTime = "";
    this.dayToken = "";
    this.dayNameCurrent = "";
    this.dayNameEn = "";
    this.dayNameAr = "";
    this.dayNameUnd = "";
    this.serviceTypeToken = "";
    this.serviceTypeNameCurrent = "";
    this.serviceToken = "";
    this.serviceNameCurrent = "";
    this.serviceNameEn = "";
    this.serviceNameAr = "";
    this.serviceNameUnd = "";
    this.serviceProvideTypeToken = "";
    this.serviceProvideTypeNameCurrent = "";
    this.serviceProvideTypeNameAr = "";
    this.serviceProvideTypeNameEn = "";
    this.serviceProvideTypeNameUnd = "";
    this.servicePrice = "";
    this.serviceTimeWithMinutes = "";
    this.maxDiscountPercentage = "";
    this.generalSpecialtyToken = "";
    this.specialSpecialtyToken = "";
    this.specialSpecialtyNameCurrent = "";
    this.specialSpecialtyNameEn = "";
    this.specialSpecialtyNameAr = "";
    this.specialSpecialtyNameUnd = "";
    this.numberInReservationServes = "";
    this.numberInArrive = "";
    this.numberRealToEntry = "";
    this.estimatedDateTimeArrival = "";
    this.estimatedDateTimeEnter = "";
    this.reservationStatusToken = "";
    this.reservationStatusNameCurrent = "";
    this.reservationStatusNameAr = "";
    this.reservationStatusNameEn = "";
    this.reservationStatusNameUnd = "";
    this.reservationRequestNotes = "";
    this.reservationDate = "";
    this.feesTypeToken = "";
    this.feesTypeNameAr = "";
    this.feesTypeNameEn = "";
    this.feesTypeNameUnd = "";
    this.feesTypeValue = "";
    this.totalPaid = "";
    this.remainingAmount = "";
    this.offerToken = "";
    this.offerCode = "";
    this.offerMediaTypeToken = "";
    this.offerMediaTypeNameCurrent = "";
    this.offerMediaTypeNameAr = "";
    this.offerMediaTypeNameEn = "";
    this.offerMediaTypeNameUnd = "";
    this.offerTitleCurrent = "";
    this.offerTitleEn = "";
    this.offerTitleAr = "";
    this.offerTitleUnd = "";
    this.offerStartDate = "";
    this.offerEndDate = "";
    this.offerDuration = "";
    this.offerStatus = "";
    this.servicePriceInOffer = "";
    this.servesActivePrice = "";
    this.servesActualPrice = "";
    this.offerDescriptionCurrent = "";
    this.offerDescriptionEn = "";
    this.offerDescriptionAr = "";
    this.offerDescriptionUnd = "";
    this.offerNotes = "";
    this.actionDateTime = "";
    this.offerMediaPath = "";
    this.approvalStatus = "";
    this.cancelStatus = "";
    this.paymentStatus = "";
    this.measurementRoomStatus = "";
    this.reservationArchiveStatus = "";
    this.servicesReservationPayData = new ServicesReservationPay();
    this.timeFrom = "";
    this.timeTo = "";
    this.dateFrom = "";
    this.dateTo = "";
    this.note = "";
    this.paginationData = new PaginationData();
    this.textSearch = "";
  }
  fillData(data) {
    this.reservationToken = data.reservationToken;
    this.reservationCode = data.reservationCode;
    this.clientToken = data.clientToken;
    this.clientNameCurrent = data.clientNameCurrent;
    this.insuranceCompanyToken = data.insuranceCompanyToken;
    this.insuranceCompanyNameCurrent = data.insuranceCompanyNameCurrent;
    this.branchToken = data.branchToken;
    this.branchNameCurrent = data.branchNameCurrent;
    this.buildingToken = data.buildingToken;
    this.floorToken = data.floorToken;
    this.chamberToken = data.chamberToken;
    this.officeToken = data.officeToken;
    this.officeNameCurrent = data.officeNameCurrent;
    this.officeTimeToken = data.officeTimeToken;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.timeNameCurrent = data.timeNameCurrent;
    this.timeNameEn = data.timeNameEn;
    this.timeNameAr = data.timeNameAr;
    this.timeNameUnd = data.timeNameUnd;
    this.officeOpenTime = data.officeOpenTime;
    this.officeCloseTime = data.officeCloseTime;
    this.dayToken = data.dayToken;
    this.dayNameCurrent = data.dayNameCurrent;
    this.dayNameEn = data.dayNameEn;
    this.dayNameAr = data.dayNameAr;
    this.dayNameUnd = data.dayNameUnd;
    this.serviceTypeToken = data.serviceTypeToken;
    this.serviceTypeNameCurrent = data.serviceTypeNameCurrent;
    this.serviceToken = data.serviceToken;
    this.serviceNameCurrent = data.serviceNameCurrent;
    this.serviceNameEn = data.serviceNameEn;
    this.serviceNameAr = data.serviceNameAr;
    this.serviceNameUnd = data.serviceNameUnd;
    this.serviceProvideTypeToken = data.serviceProvideTypeToken;
    this.serviceProvideTypeNameCurrent = data.serviceProvideTypeNameCurrent;
    this.serviceProvideTypeNameAr = data.serviceProvideTypeNameAr;
    this.serviceProvideTypeNameEn = data.serviceProvideTypeNameEn;
    this.serviceProvideTypeNameUnd = data.serviceProvideTypeNameUnd;
    this.servicePrice = data.servicePrice;
    this.serviceTimeWithMinutes = data.serviceTimeWithMinutes;
    this.maxDiscountPercentage = data.maxDiscountPercentage;
    this.specialSpecialtyToken = data.specialSpecialtyToken;
    this.specialSpecialtyNameCurrent = data.specialSpecialtyNameCurrent;
    this.specialSpecialtyNameEn = data.specialSpecialtyNameEn;
    this.specialSpecialtyNameAr = data.specialSpecialtyNameAr;
    this.specialSpecialtyNameUnd = data.specialSpecialtyNameUnd;
    this.numberInReservationServes = data.numberInReservationServes;
    this.numberInArrive = data.numberInArrive;
    this.numberRealToEntry = data.numberRealToEntry;
    this.estimatedDateTimeArrival = data.estimatedDateTimeArrival;
    this.estimatedDateTimeEnter = data.estimatedDateTimeEnter;
    this.reservationStatusToken = data.reservationStatusToken;
    this.reservationStatusNameCurrent = data.reservationStatusNameCurrent;
    this.reservationStatusNameAr = data.reservationStatusNameAr;
    this.reservationStatusNameEn = data.reservationStatusNameEn;
    this.reservationStatusNameUnd = data.reservationStatusNameUnd;
    this.reservationRequestNotes = data.reservationRequestNotes;
    this.reservationDate = data.reservationDate;
    this.feesTypeToken = data.feesTypeToken;
    this.feesTypeNameAr = data.feesTypeNameAr;
    this.feesTypeNameEn = data.feesTypeNameEn;
    this.feesTypeNameUnd = data.feesTypeNameUnd;
    this.feesTypeValue = data.feesTypeValue;
    this.totalPaid = data.totalPaid;
    this.remainingAmount = data.remainingAmount;
    this.offerToken = data.offerToken;
    this.offerCode = data.offerCode;
    this.offerMediaTypeToken = data.offerMediaTypeToken;
    this.offerMediaTypeNameCurrent = data.offerMediaTypeNameCurrent;
    this.offerMediaTypeNameAr = data.offerMediaTypeNameAr;
    this.offerMediaTypeNameEn = data.offerMediaTypeNameEn;
    this.offerMediaTypeNameUnd = data.offerMediaTypeNameUnd;
    this.offerTitleCurrent = data.offerTitleCurrent;
    this.offerTitleEn = data.offerTitleEn;
    this.offerTitleAr = data.offerTitleAr;
    this.offerTitleUnd = data.offerTitleUnd;
    this.offerStartDate = data.offerStartDate;
    this.offerEndDate = data.offerEndDate;
    this.offerDuration = data.offerDuration;
    this.offerStatus = data.offerStatus;
    this.servicePriceInOffer = data.servicePriceInOffer;
    this.servesActivePrice = data.servesActivePrice;
    this.servesActualPrice = data.servesActualPrice;
    this.offerDescriptionCurrent = data.offerDescriptionCurrent;
    this.offerDescriptionEn = data.offerDescriptionEn;
    this.offerDescriptionAr = data.offerDescriptionAr;
    this.offerDescriptionUnd = data.offerDescriptionUnd;
    this.offerNotes = data.offerNotes;
    this.actionDateTime = data.actionDateTime;
    this.offerMediaPath = data.offerMediaPath;
    this.approvalStatus = data.approvalStatus;
    this.cancelStatus = data.cancelStatus;
    this.paymentStatus = data.paymentStatus;
    this.measurementRoomStatus = data.measurementRoomStatus;
    this.reservationArchiveStatus = data.reservationArchiveStatus;
  }

  async getAllServicesReservations(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/ServicesReservations/GetAllServicesReservations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${this.paginationData.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&clientToken=${this.clientToken}&serviceTypeToken=${this.serviceTypeToken}&serviceToken=${this.serviceToken}&reservationStatusToken=${this.reservationStatusToken}&employeeToken=${this.employeeToken}&officeToken=${this.officeToken}&dayToken=${this.dayToken}&generalSpecialtyToken=${this.generalSpecialtyToken}&specialSpecialtyToken=${this.specialSpecialtyToken}&timeFrom=${this.timeFrom}&timeTo=${this.timeTo}&dateFrom=${this.dateFrom}&dateTo=${this.dateTo}&textSearch=${this.textSearch}`
      );
      this.paginationData = {
        totalPages: response.data.servicesReservationPagination.totalPages,
        totalItems: response.data.servicesReservationPagination.totalItems,
        countItemsInPage:
          response.data.servicesReservationPagination.countItemsInPage,
        selfPage: response.data.servicesReservationPagination.selfPage,
      };
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfServicesReservations(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/ServicesReservations/GetDialogOfServicesReservations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&clientToken=${this.clientToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addRequestServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      officeTimeToken: this.officeTimeToken,
      serviceToken: this.serviceToken,
      offerCode: this.offerCode,
      reservationDate: this.reservationDate,
      clientToken: this.clientToken,
      feesTypeToken: this.feesTypeToken,
      feesTypeValue: this.feesTypeValue,
      reservationRequestNotes: this.reservationRequestNotes,
      servicesReservationPayData: this.servicesReservationPayData,
    };

    try {
      let response = await axios.post(
        `/ServicesReservations/AddRequestServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async approveOnServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationListData: [
        {
          reservationToken: this.reservationToken,
          note: this.note,
        },
      ],
    };

    try {
      let response = await axios.post(
        `/ServicesReservations/ApproverServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
  async attendServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationListData: [
        {
          reservationToken: this.reservationToken,
          note: this.note,
        },
      ],
    };

    try {
      let response = await axios.post(
        `/ServicesReservations/AttendanceServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
  async enterServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationListData: [
        {
          reservationToken: this.reservationToken,
          note: this.note,
        },
      ],
    };

    try {
      let response = await axios.post(
        `/ServicesReservations/EntreeServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
  async waitForComeBackServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationListData: [
        {
          reservationToken: this.reservationToken,
          note: this.note,
        },
      ],
    };

    try {
      let response = await axios.post(
        `/ServicesReservations/WaitAndWillComeBackServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
  async finishServicesReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationToken: this.reservationToken,
      finishNote: this.note,
    };

    try {
      let response = await axios.post(
        `/ServicesReservationFinish/AddServicesReservationFinish`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
