<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateBranchRole()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'branchRoleNameAr'"
            :value="branchRoleData.branchRoleNameAr"
            v-on:changeValue="branchRoleData.branchRoleNameAr = $event"
            :title="$t('setting.others.branchRolesMsgs.nameAr')"
            :imgName="'branch-roles.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'branchRoleNameEn'"
            :value="branchRoleData.branchRoleNameEn"
            v-on:changeValue="branchRoleData.branchRoleNameEn = $event"
            :title="$t('setting.others.branchRolesMsgs.nameEn')"
            :imgName="'branch-roles.svg'"
          />
          <CustomInput
            :className="'col-md-12'"
            :id="'branchRoleNameUnd'"
            :value="branchRoleData.branchRoleNameUnd"
            v-on:changeValue="branchRoleData.branchRoleNameUnd = $event"
            :title="$t('setting.others.branchRolesMsgs.nameUnd')"
            :imgName="'branch-roles.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'branchRoleDescriptionAr'"
            :value="branchRoleData.branchRoleDescriptionAr"
            v-on:changeValue="branchRoleData.branchRoleDescriptionAr = $event"
            :title="$t('setting.others.branchRolesMsgs.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'branchRoleDescriptionEn'"
            :value="branchRoleData.branchRoleDescriptionEn"
            v-on:changeValue="branchRoleData.branchRoleDescriptionEn = $event"
            :title="$t('setting.others.branchRolesMsgs.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'branchRoleDescriptionUnd'"
            :value="branchRoleData.branchRoleDescriptionUnd"
            v-on:changeValue="branchRoleData.branchRoleDescriptionUnd = $event"
            :title="$t('setting.others.branchRolesMsgs.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'branchRoleNotes'"
            :value="branchRoleData.branchRoleNotes"
            v-on:changeValue="branchRoleData.branchRoleNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/branch-roles" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "BranchRoleForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["branchRoleData", "submitName"],
  methods: {
    async addOrUpdateBranchRole() {
      this.$emit("addOrUpdateBranchRole");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
