var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeesSettlementMovement",modifiers:{"usersPrivilegeEmployeesSettlementMovement":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("points"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeesSettlementMovement"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView
            ),expression:"\n              usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesSettlementMovementView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView
            )?_vm._i(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesSettlementMovementView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll
            ),expression:"\n              usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesSettlementMovementViewAll"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll
            )?_vm._i(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewAll
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewAll", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewAll", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewAll", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesSettlementMovementViewAll"}},[_vm._v(_vm._s(_vm.$t("viewPointsHistoryForAll")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf
            ),expression:"\n              usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesSettlementMovementViewSelf"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf
            )?_vm._i(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementViewSelf
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewSelf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewSelf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementViewSelf", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesSettlementMovementViewSelf"}},[_vm._v(_vm._s(_vm.$t("viewPointsHistoryForSelf")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd
            ),expression:"\n              usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesSettlementMovementAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesSettlementMovementAdd"}},[_vm._v(_vm._s(_vm.$t("pointsSettlementForUser")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete
            ),expression:"\n              usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesSettlementMovementDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesSettlementMovement.employeesSettlementMovementDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesSettlementMovement, "employeesSettlementMovementDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesSettlementMovementDelete"}},[_vm._v(_vm._s(_vm.$t("cancelPointsSettlementForUser")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }