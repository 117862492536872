<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="employeeBranchesData">
      <EmployeeBranchesTable
        :employeeBranchesData="employeeBranchesData"
        :employeeBranchData="employeeBranchData"
        v-on:setEmployeeBranchData="employeeBranchData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="filterEmployeeBranchData.paginationData"
        v-on:changePage="changePage"
      />
      <EmployeeBranchInfo :employeeBranchData="employeeBranchData" />
      <EmployeeBranchDelete
        :employeeBranchData="employeeBranchData"
        v-on:refresh="getAllEmployeeBranches()"
      />
    </div>

    <EmployeeBranchFloatBtns
      :filterEmployeeBranchData="filterEmployeeBranchData"
      v-on:search="getAllEmployeeBranches()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeBranchesTable from "@/components/employees/employeeBranches/EmployeeBranchesTable.vue";
import EmployeeBranchInfo from "@/components/employees/employeeBranches/EmployeeBranchInfo.vue";
import EmployeeBranchDelete from "@/components/employees/employeeBranches/EmployeeBranchDelete.vue";
import EmployeeBranchFloatBtns from "@/components/employees/employeeBranches/EmployeeBranchFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import EmployeeBranch from "@/models/employees/employeeBranches/EmployeeBranch";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeBranches",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeBranchesTable,
    EmployeeBranchInfo,
    EmployeeBranchDelete,
    EmployeeBranchFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeBranchesData: null,
      employeeBranchData: new EmployeeBranch(),
      filterEmployeeBranchData: new EmployeeBranch(),
    };
  },
  methods: {
    changePage(page) {
      this.filterEmployeeBranchData.paginationData.selfPage = page;
      this.getAllEmployeeBranches();
    },
    refresh() {
      this.employeeBranchesData = null;
      this.getAllEmployeeBranches();
    },
    async getAllEmployeeBranches() {
      this.isLoading = true;
      try {
        let response =
          await this.filterEmployeeBranchData.getAllEmployeeBranches(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeBranchesData =
            response.data.employeeBranchesPagination.employeeBranchesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeBranchesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeBranchesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeBranchesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeeBranchesData = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.filterEmployeeBranchData.employeeToken =
      this.$route.params.employeeToken || "";
    this.filterEmployeeBranchData.branchToken =
      this.$route.params.branchToken || "";
    this.getAllEmployeeBranches();
  },
};
</script>
