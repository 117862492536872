<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EstablishmentRoleForm
      v-if="!isLoading"
      :establishmentRoleData="establishmentRoleData"
      :submitName="$t('edit')"
      v-on:addOrUpdateEstablishmentRole="addOrUpdateEstablishmentRole"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EstablishmentRoleForm from "@/components/settings/settingsOther/establishmentRoles/EstablishmentRoleForm.vue";
import EstablishmentRole from "@/models/settings/settingsOther/establishmentRoles/EstablishmentRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EstablishmentRoleEdit",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EstablishmentRoleForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      establishmentRoleData: new EstablishmentRole(),
      establishmentRoleToken: this.$route.params.establishmentRoleToken,
    };
  },
  methods: {
    async getEstablishmentRoleDetails() {
      this.isLoading = true;
      this.establishmentRoleData.establishmentRoleToken =
        this.establishmentRoleToken;
      try {
        let response =
          await this.establishmentRoleData.getEstablishmentRoleDetails(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.establishmentRoleData.fillData(
            response.data.EstablishmentRoleData
          );
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.establishmentRoleData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.establishmentRoleData = null;
      }
      this.isLoading = false;
    },
    async addOrUpdateEstablishmentRole() {
      this.isLoading = true;
      try {
        let response =
          await this.establishmentRoleData.addOrUpdateEstablishmentRole(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push("/establishment-roles");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEstablishmentRoleDetails();
  },
};
</script>

<style scoped lang="scss"></style>
