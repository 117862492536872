import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";

export default class EmployeeBranch {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeBranchesToken = "";
    this.employeeBranchesCode = "";
    this.employeeCode = "";
    this.employeeImagePath = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeCountryCode = "";
    this.employeePhone = "";
    this.employeePhoneWithCC = "";
    this.branchToken = "";
    this.branchNameCurrent = "";
    this.branchNameAr = "";
    this.branchNameEn = "";
    this.branchNameUnd = "";
    this.branchImagePath = "";
    this.branchRoleToken = "";
    this.branchRoleNameCurrent = "";
    this.employeeWorkPlaceTypeToken = "";
    this.employeeWorkPlaceTypeNameCurrent = "";
    this.employeeWorkPlaceTypeNameEn = "";
    this.employeeWorkPlaceTypeNameAr = "";
    this.employeeWorkPlaceTypeNameUnd = "";
    this.employeeBranchesNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.employeeBranchesArchiveStatus = "";
    this.branchesTokenList = [];
    this.employeeTokenList = [];
    this.paginationData = new PaginationData();
    this.textSearch = "";
  }
  fillData(data) {
    this.employeeBranchesToken = data.employeeBranchesToken;
    this.employeeBranchesCode = data.employeeBranchesCode;
    this.employeeCode = data.employeeCode;
    this.employeeImagePath = data.employeeImagePath;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeCountryCode = data.employeeCountryCode;
    this.employeePhone = data.employeePhone;
    this.employeePhoneWithCC = data.employeePhoneWithCC;
    this.branchToken = data.branchToken;
    this.branchNameCurrent = data.branchNameCurrent;
    this.branchNameAr = data.branchNameAr;
    this.branchNameEn = data.branchNameEn;
    this.branchNameUnd = data.branchNameUnd;
    this.branchImagePath = data.branchImagePath;
    this.branchRoleToken = data.branchRoleToken;
    this.branchRoleNameCurrent = data.branchRoleNameCurrent;
    this.employeeWorkPlaceTypeToken = data.employeeWorkPlaceTypeToken;
    this.employeeWorkPlaceTypeNameCurrent =
      data.employeeWorkPlaceTypeNameCurrent;
    this.employeeWorkPlaceTypeNameEn = data.employeeWorkPlaceTypeNameEn;
    this.employeeWorkPlaceTypeNameAr = data.employeeWorkPlaceTypeNameAr;
    this.employeeWorkPlaceTypeNameUnd = data.employeeWorkPlaceTypeNameUnd;
    this.employeeBranchesNotes = data.employeeBranchesNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.employeeBranchesArchiveStatus = data.employeeBranchesArchiveStatus;
  }

  async getAllEmployeeBranches(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeeBranches/GetAllEmployeeBranches?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${this.paginationData.selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&sortToken=${this.sortToken}&branchToken=${this.branchToken}&employeeToken=${this.employeeToken}&textSearch=${this.textSearch}`
      );
      this.paginationData = {
        totalPages: response.data.employeeBranchesPagination.totalPages,
        totalItems: response.data.employeeBranchesPagination.totalItems,
        countItemsInPage:
          response.data.employeeBranchesPagination.countItemsInPage,
        selfPage: response.data.employeeBranchesPagination.selfPage,
      };
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getEmployeeBranchesDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeeBranches/GetEmployeeBranchesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeBranchesToken=${this.employeeBranchesToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addEmployeeBranches(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeBranchesCode: this.employeeBranchesCode,
      branchRoleToken: this.branchRoleToken,
      employeeBranchesNotes: this.employeeBranchesNotes,
      employeeWorkPlaceTypeToken: this.employeeWorkPlaceTypeToken,
      employeeTokenList: this.employeeTokenList,
      branchesTokenList: this.branchesTokenList,
    };

    try {
      let response = await axios.post(
        `/EmployeeBranches/AddEmployeeBranches`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async updateEmployeeBranch(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeBranchesToken: this.employeeBranchesToken,
      employeeWorkPlaceTypeToken: this.employeeWorkPlaceTypeToken,
      branchRoleToken: this.branchRoleToken,
      employeeBranchesNotes: this.employeeBranchesNotes,
    };

    try {
      let response = await axios.post(
        `/EmployeeBranches/UpdateEmployeeBranches`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async finalDeleteEmployeeBranch(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeBranchesToken: this.employeeBranchesToken,
    };

    try {
      let response = await axios.post(
        `/EmployeeBranches/FinalDeleteEmployeeBranches`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
