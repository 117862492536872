<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEmployeeBranch()"
    >
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'employeeWorkPlaceTypeToken'"
            :value="employeeBranchData.employeeWorkPlaceTypeToken"
            :options="employeeWorkPlaceTypeTokenOptions"
            v-on:changeValue="
              employeeBranchData.employeeWorkPlaceTypeToken = $event
            "
            :title="$t('selectEmployeeWorkPlaceType')"
            :imgName="'type.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'branchRoleToken'"
            :value="employeeBranchData.branchRoleToken"
            :options="branchRoleTokenOptions"
            v-on:changeValue="employeeBranchData.branchRoleToken = $event"
            :title="$t('setting.others.branchRolesMsgs.select')"
            :imgName="'branch-roles.svg'"
          />

          <!-- <CustomSelectBox
            v-if="submitName == $t('add')"
            :className="'col-md-6'"
            :id="'employeeToken'"
            :value="employeeBranchData.employeeToken"
            :options="employeeTokenOptions"
            v-on:changeValue="employeeBranchData.employeeToken = $event"
            :title="$t('selectEmployee')"
            :imgName="'employees.svg'"
          />
          <CustomSelectBox
            v-if="submitName == $t('add')"
            :className="'col-md-6'"
            :id="'branchToken'"
            :value="employeeBranchData.branchToken"
            :options="branchTokenOptions"
            v-on:changeValue="employeeBranchData.branchToken = $event"
            :title="$t('selectBranch')"
            :imgName="'branches.svg'"
          /> -->
        </div>
      </div>

      <div v-if="submitName == $t('add')">
        <b-button v-b-toggle.theBranches class="btn btn-lg btn-collapse">
          {{ $t("theBranches") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse id="theBranches">
          <div class="my-card">
            <span class="my-card-title">{{ $t("theBranches") }}</span>
            <div>
              <input
                type="checkbox"
                id="branchSelectAll"
                @click="branchSelectAll"
                v-model="branchAllSelected"
                class="checkbox"
              />
              <label class="mt-1 ml-2" for="branchSelectAll">{{
                $t("selectAll")
              }}</label>
            </div>

            <div v-if="branchesData">
              <div class="row">
                <div
                  class="flip-card col-sm-6 col-md-4 col-lg-3"
                  v-for="(branch, index) in branchesData"
                  :key="index"
                >
                  <div class="card-inner">
                    <div class="card-front">
                      <div class="flip-card-title-top">
                        <h6 class="cut-1line">{{ branch.branchCode }}</h6>
                      </div>
                      <img
                        :src="baseUrl + branch.branchImagePath"
                        :onerror="`this.src='${defaultImg}'`"
                        alt="Image"
                      />
                      <div class="flip-card-title">
                        <h4 class="cut-1line">
                          {{
                            language == "ar"
                              ? branch.branchNameAr
                              : branch.branchNameEn
                          }}
                        </h4>
                      </div>
                      <input
                        type="checkbox"
                        v-model="employeeBranchData.branchesTokenList"
                        @click="branchSelect"
                        :value="branch.branchToken"
                        class="checkbox item-right-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ExceptionWithImg v-else :msg="exceptionMsgBranch" />
          </div>
        </b-collapse>

        <b-button v-b-toggle.employees class="btn btn-lg btn-collapse">
          {{ $t("employees") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </b-button>
        <b-collapse id="employees">
          <div class="my-card">
            <span class="my-card-title">{{ $t("employees") }}</span>
            <div>
              <input
                type="checkbox"
                id="employeeSelectAll"
                @click="employeeSelectAll"
                v-model="employeeAllSelected"
                class="checkbox"
              />
              <label class="mt-1 ml-2" for="employeeSelectAll">{{
                $t("selectAll")
              }}</label>
            </div>

            <div v-if="employeesData">
              <div class="row">
                <div
                  class="flip-card col-sm-6 col-md-4 col-lg-3"
                  v-for="(employee, index) in employeesData"
                  :key="index"
                >
                  <div class="card-inner">
                    <div class="card-front">
                      <div class="flip-card-title-top">
                        <h6 class="cut-1line">{{ employee.employeeCode }}</h6>
                      </div>
                      <img
                        :src="baseUrl + employee.employeeImagePath"
                        :onerror="`this.src='${defaultImg}'`"
                        alt="Image"
                      />
                      <div class="flip-card-title">
                        <h4 class="cut-1line">
                          {{
                            language == "ar"
                              ? employee.employeeNameAr
                              : employee.employeeNameEn
                          }}
                        </h4>
                      </div>
                      <input
                        type="checkbox"
                        v-model="employeeBranchData.employeeTokenList"
                        @click="employeeSelect"
                        :value="employee.employeeToken"
                        class="checkbox item-right-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ExceptionWithImg v-else :msg="exceptionMsgEmployee" />
          </div>
        </b-collapse>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/employee-branches" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS, BASE_URL } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { setDataMultiLang } from "@/utils/functions";
import axios from "axios";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import BranchRole from "@/models/settings/settingsOther/branchRoles/BranchRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeBranchForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionMsgBranch: null,
      exceptionMsgEmployee: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      baseUrl: BASE_URL,
      defaultImg: DEFAULT_IMG,
      employeeWorkPlaceTypeTokenOptions: [],
      branchRoleData: new BranchRole(),
      branchRoleTokenOptions: [],
      branchesData: null,
      branchAllSelected: false,
      employeesData: null,
      employeeAllSelected: false,
    };
  },
  props: ["employeeBranchData", "submitName"],
  methods: {
    branchSelectAll() {
      this.branchAllSelected = !this.branchAllSelected;
      this.employeeBranchData.branchesTokenList = [];

      if (this.branchAllSelected) {
        for (let branch in this.branchesData) {
          this.employeeBranchData.branchesTokenList.push(
            this.branchesData[branch].branchToken
          );
        }
      }
    },
    branchSelect() {
      setTimeout(() => {
        if (
          this.branchesData.length ==
          this.employeeBranchData.branchesTokenList.length
        )
          this.branchAllSelected = true;
        else this.branchAllSelected = false;
      }, 500);
    },
    employeeSelectAll() {
      this.employeeAllSelected = !this.employeeAllSelected;
      this.employeeBranchData.employeeTokenList = [];

      if (this.employeeAllSelected) {
        for (let employee in this.employeesData) {
          this.employeeBranchData.employeeTokenList.push(
            this.employeesData[employee].employeeToken
          );
        }
      }
    },
    employeeSelect() {
      setTimeout(() => {
        if (
          this.employeesData.length ==
          this.employeeBranchData.employeeTokenList.length
        )
          this.employeeAllSelected = true;
        else this.employeeAllSelected = false;
      }, 500);
    },
    async getDialogOfEmployeeWorkPlaceTypes() {
      this.isLoading = true;
      this.employeeWorkPlaceTypeTokenOptions = [];
      this.employeeWorkPlaceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployeeWorkPlaceType"),
      });
      let genders =
        this.$store.getters.userData.constantLists.listEmployeeWorkPlaceType;
      for (let item in genders) {
        this.employeeWorkPlaceTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfBranchRoles() {
      this.isLoading = true;
      this.branchRoleTokenOptions = [];
      this.branchRoleTokenOptions.push({
        value: "",
        text: this.$t("setting.others.branchRolesMsgs.select"),
      });
      try {
        let response = await this.branchRoleData.getDialogOfBranchRoles(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchRoleTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("selectBranch"),
      });
      try {
        const response = await axios.get(
          `/Branches/GetDialogOfBranches?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getAllEmployees() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/Employees/GetAllEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&paginationStatus=false`
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsgEmployee = null;
          this.employeesData = response.data.employeesPagination.employeesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeesData = null;
          this.exceptionMsgEmployee = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeesData = null;
          this.exceptionMsgEmployee = response.data.msg;
        }
        this.isLoading = false;
      } catch (error) {
        this.employeesData = null;
        this.exceptionMsgEmployee = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    async getAllBranches() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `/Branches/GetAllBranches?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&paginationStatus=false`
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsgBranch = null;
          this.branchesData = response.data.branchesPagination.branchesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.branchesData = null;
          this.exceptionMsgBranch = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.branchesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.branchesData = null;
          this.exceptionMsgBranch = response.data.msg;
        }
        this.isLoading = false;
      } catch (error) {
        this.branchesData = null;
        this.exceptionMsgBranch = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeeBranch() {
      this.$emit("addOrUpdateEmployeeBranch");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  async created() {
    this.getDialogOfEmployeeWorkPlaceTypes();
    this.getDialogOfBranchRoles();
    if (this.submitName == this.$t("add")) {
      this.getAllEmployees();
      this.getAllBranches();
    }
  },
};
</script>

<style lang="scss"></style>
