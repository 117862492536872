import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";

export default class StoreRole {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.storeRoleToken = "";
    this.storeRoleNameCurrent = "";
    this.storeRoleNameEn = "";
    this.storeRoleNameAr = "";
    this.storeRoleNameUnd = "";
    this.roleTypeToken = "RTT-1";
    this.roleTypeNameEn = "";
    this.roleTypeNameAr = "";
    this.roleTypeNameUnd = "";
    this.roleTypeNameCurrent = "";
    this.storeRoleDescriptionCurrent = "";
    this.storeRoleDescriptionEn = "";
    this.storeRoleDescriptionAr = "";
    this.storeRoleDescriptionUnd = "";
    this.storeRoleNotes = "";
    this.storeRoleArchiveStatus = "";
    this.paginationData = new PaginationData();
    this.textSearch = "";
  }
  fillData(data) {
    this.storeRoleToken = data.storeRoleToken;
    this.storeRoleNameCurrent = data.storeRoleNameCurrent;
    this.storeRoleNameEn = data.storeRoleNameEn;
    this.storeRoleNameAr = data.storeRoleNameAr;
    this.storeRoleNameUnd = data.storeRoleNameUnd;
    this.roleTypeToken = data.roleTypeToken;
    this.roleTypeNameEn = data.roleTypeNameEn;
    this.roleTypeNameAr = data.roleTypeNameAr;
    this.roleTypeNameUnd = data.roleTypeNameUnd;
    this.roleTypeNameCurrent = data.roleTypeNameCurrent;
    this.storeRoleDescriptionCurrent = data.storeRoleDescriptionCurrent;
    this.storeRoleDescriptionEn = data.storeRoleDescriptionEn;
    this.storeRoleDescriptionAr = data.storeRoleDescriptionAr;
    this.storeRoleDescriptionUnd = data.storeRoleDescriptionUnd;
    this.storeRoleNotes = data.storeRoleNotes;
    this.storeRoleArchiveStatus = data.storeRoleArchiveStatus;
  }

  async getAllStoreRoles(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/StoreRoles/GetAllStoreRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${this.paginationData.selfPage}&paginationStatus=&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${this.textSearch}`
      );
      this.paginationData = {
        totalPages: response.data.storeRolesPagination.totalPages,
        totalItems: response.data.storeRolesPagination.totalItems,
        countItemsInPage: response.data.storeRolesPagination.countItemsInPage,
        selfPage: response.data.storeRolesPagination.selfPage,
      };
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getStoreRoleDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/StoreRoles/GetStoreRoleDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&storeRoleToken=${this.storeRoleToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfStoreRoles(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/StoreRoles/GetDialogOfStoreRoles?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateStoreRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      storeRoleToken: this.storeRoleToken,
      storeRoleNameAr: this.storeRoleNameAr,
      storeRoleNameUnd: this.storeRoleNameUnd,
      roleNameUnd: this.roleNameUnd,
      roleTypeToken: this.roleTypeToken,
      storeRoleDescriptionEn: this.storeRoleDescriptionEn,
      storeRoleDescriptionAr: this.storeRoleDescriptionAr,
      storeRoleDescriptionUnd: this.storeRoleDescriptionUnd,
      storeRoleNotes: this.storeRoleNotes,
    };

    try {
      let response = "";
      if (this.storeRoleToken == "" || this.storeRoleToken == undefined) {
        response = await axios.post(`/StoreRoles/AddStoreRole`, data);
      } else {
        response = await axios.post(`/StoreRoles/UpdateStoreRole`, data);
      }
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveStoreRole(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      storeRoleToken: this.storeRoleToken,
    };

    try {
      let response = await axios.post(`/StoreRoles/ArchiveStoreRole`, data);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
