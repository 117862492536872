<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="establishmentRolesData">
      <EstablishmentRolesTable
        :establishmentRolesData="establishmentRolesData"
        :establishmentRoleData="establishmentRoleData"
        v-on:setEstablishmentRoleData="establishmentRoleData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="filterEstablishmentRoleData.paginationData"
        v-on:changePage="changePage"
      />
      <EstablishmentRoleInfo :establishmentRoleData="establishmentRoleData" />
      <EstablishmentRoleDelete
        :establishmentRoleData="establishmentRoleData"
        v-on:refresh="getAllEstablishmentRoles()"
      />
    </div>

    <EstablishmentRoleFloatBtns
      :filterEstablishmentRoleData="filterEstablishmentRoleData"
      v-on:search="getAllEstablishmentRoles()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EstablishmentRolesTable from "@/components/settings/settingsOther/establishmentRoles/EstablishmentRolesTable.vue";
import EstablishmentRoleInfo from "@/components/settings/settingsOther/establishmentRoles/EstablishmentRoleInfo.vue";
import EstablishmentRoleDelete from "@/components/settings/settingsOther/establishmentRoles/EstablishmentRoleDelete.vue";
import EstablishmentRoleFloatBtns from "@/components/settings/settingsOther/establishmentRoles/EstablishmentRoleFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import EstablishmentRole from "@/models/settings/settingsOther/establishmentRoles/EstablishmentRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EstablishmentRoles",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EstablishmentRolesTable,
    EstablishmentRoleInfo,
    EstablishmentRoleDelete,
    EstablishmentRoleFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      establishmentRolesData: null,
      establishmentRoleData: new EstablishmentRole(),
      filterEstablishmentRoleData: new EstablishmentRole(),
    };
  },
  methods: {
    changePage(page) {
      this.filterEstablishmentRoleData.paginationData.selfPage = page;
      this.getAllEstablishmentRoles();
    },
    refresh() {
      this.establishmentRolesData = null;
      this.getAllEstablishmentRoles();
    },
    async getAllEstablishmentRoles() {
      this.isLoading = true;
      try {
        let response =
          await this.filterEstablishmentRoleData.getAllEstablishmentRoles(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.establishmentRolesData =
            response.data.EstablishmentRolesPagination.establishmentRolesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.establishmentRolesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.establishmentRolesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.establishmentRolesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.establishmentRolesData = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllEstablishmentRoles();
  },
};
</script>
