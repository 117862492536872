<template>
  <b-modal id="StoreRoleInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/store-roles.svg" class="icon-lg" />
        {{ $t("setting.others.storeRolesMsgs.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="storeRoleData.storeRoleNameAr"
        :title="$t('setting.others.storeRolesMsgs.nameAr')"
        :imgName="'store-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="storeRoleData.storeRoleNameEn"
        :title="$t('setting.others.storeRolesMsgs.nameEn')"
        :imgName="'store-roles.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="storeRoleData.storeRoleNameUnd"
        :title="$t('setting.others.storeRolesMsgs.nameUnd')"
        :imgName="'store-roles.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="storeRoleData.storeRoleDescriptionAr"
        :title="$t('setting.others.storeRolesMsgs.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="storeRoleData.storeRoleDescriptionEn"
        :title="$t('setting.others.storeRolesMsgs.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="storeRoleData.storeRoleDescriptionUnd"
        :title="$t('setting.others.storeRolesMsgs.descriptionUnd')"
        :imgName="'description.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="storeRoleData.storeRoleNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { setDataMultiLang, timeToLang } from "@/utils/functions";

export default {
  name: "StoreRoleInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["storeRoleData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  async created() {},
};
</script>

<style scoped lang="scss"></style>
