var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.establishmentYearSettings",modifiers:{"establishmentYearSettings":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("establishmentYearSettings"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"establishmentYearSettings"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore
            ),expression:"\n              usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"establishmentYearSettingRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore
            )?_vm._i(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore
            ,null)>-1:(
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEstablishmentYearSetting.establishmentYearSettingRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEstablishmentYearSetting, "establishmentYearSettingRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"establishmentYearSettingRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }