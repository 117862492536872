export default class UsersPrivilegeEmployeesMoveSlides {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesMoveSlidesView = false;
    this.employeesMoveSlidesViewSelf = false;
    this.employeesMoveSlidesAdd = false;
    this.employeesMoveSlidesUpdate = false;
    this.employeesMoveSlidesArchive = false;
    this.employeesMoveSlidesRestore = false;
    this.employeesMoveSlidesDelete = false;
  }
  fillData(data) {
    this.employeesMoveSlidesView = data.employeesMoveSlidesView;
    this.employeesMoveSlidesViewSelf = data.employeesMoveSlidesViewSelf;
    this.employeesMoveSlidesAdd = data.employeesMoveSlidesAdd;
    this.employeesMoveSlidesUpdate = data.employeesMoveSlidesUpdate;
    this.employeesMoveSlidesArchive = data.employeesMoveSlidesArchive;
    this.employeesMoveSlidesRestore = data.employeesMoveSlidesRestore;
    this.employeesMoveSlidesDelete = data.employeesMoveSlidesDelete;
  }
}
