var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.usersPrivilegeEmployeesMovementMedai",modifiers:{"usersPrivilegeEmployeesMovementMedai":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("media"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"usersPrivilegeEmployeesMovementMedai"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore
            ),expression:"\n              usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"employeesMovementMediaRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore
            )?_vm._i(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore
            ,null)>-1:(
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeEmployeesMovementMedai.employeesMovementMediaRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeEmployeesMovementMedai, "employeesMovementMediaRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"employeesMovementMediaRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }