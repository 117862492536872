<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateEstablishmentRole()"
    >
      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentRoleNameAr'"
            :value="establishmentRoleData.establishmentRoleNameAr"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleNameAr = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.nameAr')"
            :imgName="'establishment-roles.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'establishmentRoleNameEn'"
            :value="establishmentRoleData.establishmentRoleNameEn"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleNameEn = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.nameEn')"
            :imgName="'establishment-roles.svg'"
          />
          <CustomInput
            :className="'col-md-12'"
            :id="'establishmentRoleNameUnd'"
            :value="establishmentRoleData.establishmentRoleNameUnd"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleNameUnd = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.nameUnd')"
            :imgName="'establishment-roles.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionAr'"
            :value="establishmentRoleData.establishmentRoleDescriptionAr"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleDescriptionAr = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionEn'"
            :value="establishmentRoleData.establishmentRoleDescriptionEn"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleDescriptionEn = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleDescriptionUnd'"
            :value="establishmentRoleData.establishmentRoleDescriptionUnd"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleDescriptionUnd = $event
            "
            :title="$t('setting.others.establishmentRolesMsgs.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'establishmentRoleNotes'"
            :value="establishmentRoleData.establishmentRoleNotes"
            v-on:changeValue="
              establishmentRoleData.establishmentRoleNotes = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/establishment-roles" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "EstablishmentRoleForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  props: ["establishmentRoleData", "submitName"],
  methods: {
    async addOrUpdateEstablishmentRole() {
      this.$emit("addOrUpdateEstablishmentRole");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
