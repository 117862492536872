<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="branchRolesData">
      <BranchRolesTable
        :branchRolesData="branchRolesData"
        :branchRoleData="branchRoleData"
        v-on:setBranchRoleData="branchRoleData.fillData($event)"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="filterBranchRoleData.paginationData"
        v-on:changePage="changePage"
      />
      <BranchRoleInfo :branchRoleData="branchRoleData" />
      <BranchRoleDelete
        :branchRoleData="branchRoleData"
        v-on:refresh="getAllBranchRoles()"
      />
    </div>

    <BranchRoleFloatBtns
      :filterBranchRoleData="filterBranchRoleData"
      v-on:search="getAllBranchRoles()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import BranchRolesTable from "@/components/settings/settingsOther/branchRoles/BranchRolesTable.vue";
import BranchRoleInfo from "@/components/settings/settingsOther/branchRoles/BranchRoleInfo.vue";
import BranchRoleDelete from "@/components/settings/settingsOther/branchRoles/BranchRoleDelete.vue";
import BranchRoleFloatBtns from "@/components/settings/settingsOther/branchRoles/BranchRoleFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import BranchRole from "@/models/settings/settingsOther/branchRoles/BranchRole";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "BranchRoles",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    BranchRolesTable,
    BranchRoleInfo,
    BranchRoleDelete,
    BranchRoleFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      branchRolesData: null,
      branchRoleData: new BranchRole(),
      filterBranchRoleData: new BranchRole(),
    };
  },
  methods: {
    changePage(page) {
      this.filterBranchRoleData.paginationData.selfPage = page;
      this.getAllBranchRoles();
    },
    refresh() {
      this.branchRolesData = null;
      this.getAllBranchRoles();
    },
    async getAllBranchRoles() {
      this.isLoading = true;
      try {
        let response = await this.filterBranchRoleData.getAllBranchRoles(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.branchRolesData =
            response.data.branchRolesPagination.branchRolesData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.branchRolesData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.branchRolesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.branchRolesData = null;
          this.exceptionMsg = response.data.msg;
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.branchRolesData = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllBranchRoles();
  },
};
</script>
