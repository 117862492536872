<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="employeeData.attendedPlaceToken"
              :options="branchTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/branches.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="employeeData.attendanceTypeToken"
              :options="attendanceTypeTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/type.svg" />
          </div>

          <!-- <div class="form-label-group col-md-6">
            <b-form-select
              v-model="employeeData.employeeSalaryEffectToken"
              :options="employeeSalaryEffectTokenOptions"
              @change="changeSalaryEffect()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/salary-effects.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="employeeData.calculationMethodTypeToken"
              :options="calculationMethodTypeTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/type.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="employeeData.employeeSalaryEffectFeeTypeToken"
              :options="employeeSalaryEffectFeeTypeTokenOptions"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/type.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <input
              name="employeeSalaryEffectValue"
              id="employeeSalaryEffectValue"
              type="text"
              v-model="employeeData.employeeSalaryEffectValue"
              class="form-control"
              placeholder=" "
              maxlength="50"
            />
            <label for="employeeSalaryEffectValue">{{
              $t("employeeSalaryEffectValue")
            }}</label>
            <img src="@/assets/images/money.svg" />
          </div> -->

          <div class="col-12 text-center">
            <label>{{ $t("dataOfStart") }}</label>
          </div>
          <DatePicker
            :className="'col-md-6'"
            :id="'attendedDate'"
            :value="employeeData.attendedDate"
            v-on:changeValue="employeeData.attendedDate = $event"
            :title="$t('date')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="employeeData.attendedTime"
            v-on:changeValue="employeeData.attendedTime = $event"
            :title="$t('time')"
            :language="language"
          />
          <TextArea
            :className="'col-12'"
            :id="'attendedNote'"
            :value="employeeData.attendedNote"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="employeeData.attendedNote = $event"
          />

          <div class="col-12 text-center">
            <label>{{ $t("dataOfEnd") }}</label>
          </div>
          <DatePicker
            :className="'col-md-6'"
            :id="'departureDate'"
            :value="employeeData.departureDate"
            v-on:changeValue="employeeData.departureDate = $event"
            :title="$t('date')"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :value="employeeData.departureTime"
            v-on:changeValue="employeeData.departureTime = $event"
            :title="$t('time')"
            :language="language"
          />
          <TextArea
            :className="'col-12'"
            :id="'departureNote'"
            :value="employeeData.departureNote"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="employeeData.departureNote = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployeeAttendance"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="'/attendance-employee/' + employeeData.employeeToken"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import TextArea from "@/components/general/TextArea.vue";
import { setDataMultiLang } from "@/utils/functions";

export default {
  name: "EmployeeAttendanceForm",
  components: {
    PreLoader,
    ExceptionWithImg,
    DatePicker,
    TimePicker,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      attendanceTypeTokenOptions: [],
      branchTokenOptions: [],
      employeeSalaryEffectTokenOptions: [],
      calculationMethodTypeTokenOptions: [],
      employeeSalaryEffectFeeTypeTokenOptions: [],
    };
  },
  props: ["employeeData", "submitName"],
  methods: {
    async addOrUpdateEmployeeAttendance() {
      this.$emit("addOrUpdateEmployeeAttendance");
    },
    async changeAttendanceType() {
      this.employeeData.employeeSalaryEffectToken = "";
      this.employeeData.calculationMethodTypeToken = "";
      this.employeeData.employeeSalaryEffectFeeTypeToken = "";
      this.employeeData.employeeSalaryEffectValue = "";
      this.getDialogOfEmployeeSalaryEffect(
        this.employeeData.attendanceTypeToken
      );
    },
    async changeSalaryEffect() {
      this.isLoading = true;
      try {
        let response = await axios.get(
          `/EmployeeSalaryEffect/GetEmployeeSalaryEffectDetails?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&employeeSalaryEffectToken=${this.employeeData.employeeSalaryEffectToken}`
        );
        let employeeSalaryEffectData = response.data.employeeSalaryEffectData;
        if (response.data.status == STATUS.SUCCESS) {
          this.employeeData.calculationMethodTypeToken =
            employeeSalaryEffectData.calculationMethodTypeToken;
          this.employeeData.employeeSalaryEffectFeeTypeToken =
            employeeSalaryEffectData.employeeSalaryEffectFeeTypeToken;
          this.employeeData.employeeSalaryEffectValue =
            employeeSalaryEffectData.defaultValue;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
    },
    async getDialogOfEmployeeAttendanceTypes() {
      this.isLoading = true;
      this.attendanceTypeTokenOptions = [];
      this.attendanceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectOperationType"),
      });
      let items =
        this.$store.getters.userData.constantLists.listEmployeeAttendanceType;
      for (let item in items) {
        this.attendanceTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("selectBranch"),
      });
      try {
        const response = await axios.get(
          `/Branches/GetDialogOfBranches?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeBranchesAndStores() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("selectBranch"),
      });
      try {
        const response = await axios.get(
          `/Users//GetDialogOfEmployeeBranchesAndStores?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&userSelfDataStatus=flase&employeeToken=${this.employeeData.employeeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async getDialogOfEmployeeSalaryEffect(employeeSalaryEffectTypeToken) {
      this.isLoading = true;
      employeeSalaryEffectTypeToken = employeeSalaryEffectTypeToken
        ? employeeSalaryEffectTypeToken
        : "";
      this.employeeSalaryEffectTokenOptions = [];
      this.employeeSalaryEffectTokenOptions.push({
        value: "",
        text: this.$t("selectSalaryEffect"),
      });
      try {
        const response = await axios.get(
          `/EmployeeSalaryEffect/GetDialogOfEmployeeSalaryEffect?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&employeeSalaryEffectTypeToken=${employeeSalaryEffectTypeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeSalaryEffectTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeesData = null;
      }
      this.isLoading = false;
    },
    async getDialogOfCalculationMethodType() {
      this.isLoading = true;
      this.calculationMethodTypeTokenOptions = [];
      this.calculationMethodTypeTokenOptions.push({
        value: "",
        text: this.$t("selectCalculationMethodType"),
      });
      let items =
        this.$store.getters.userData.constantLists.listCalculationMethodType;
      for (let item in items) {
        this.calculationMethodTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfFeesType() {
      this.isLoading = true;
      this.employeeSalaryEffectFeeTypeTokenOptions = [];
      this.employeeSalaryEffectFeeTypeTokenOptions.push({
        value: "",
        text: this.$t("selectSalaryEffectFeeType"),
      });
      let items = this.$store.getters.userData.constantLists.listFeesType;
      for (let item in items) {
        this.employeeSalaryEffectFeeTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
  },
  async created() {
    this.getDialogOfEmployeeAttendanceTypes();
    // this.getDialogOfBranches();
    this.getDialogOfEmployeeBranchesAndStores();
    // this.getDialogOfEmployeeSalaryEffect();
    // this.getDialogOfCalculationMethodType();
    // this.getDialogOfFeesType();
    // setTimeout(
    //   () =>
    //     this.getDialogOfEmployeeSalaryEffect(
    //       this.employeeData.attendanceTypeToken
    //     ),
    //   2000
    // );
  },
};
</script>

<style scoped; lang="scss"></style>
