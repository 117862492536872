<template>
  <div v-if="treasuriesTransactionsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("branchName") }}</th>
            <th>{{ $t("treasuryName") }}</th>
            <th>{{ $t("transactionCode") }}</th>
            <th>{{ $t("transactionSerialNumber") }}</th>
            <th>{{ $t("money") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("userMakeAction") }}</th>
            <th>{{ $t("notes") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(treasuryTransaction, index) in treasuriesTransactionsData"
            :key="treasuryTransaction.transactionToken"
          >
            <td>{{ ++index }}</td>
            <td>{{ isDataExist(treasuryTransaction.branchNameCurrent) }}</td>
            <td>{{ isDataExist(treasuryTransaction.treasuryNameCurrent) }}</td>
            <td>{{ isDataExist(treasuryTransaction.transactionCode) }}</td>
            <td>
              {{ isDataExist(treasuryTransaction.transactionSerialNumber) }}
            </td>
            <td>
              {{
                isDataExist(treasuryTransaction.transactionMoney + $t("EGP"))
              }}
            </td>
            <td>{{ timeToLang(treasuryTransaction.transactionDate) }}</td>
            <td>{{ isDataExist(treasuryTransaction.userNameCurrent) }}</td>
            <td>{{ isDataExist(treasuryTransaction.transactionNote) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { setDataMultiLang, timeToLang, isDataExist } from "@/utils/functions";

export default {
  name: "TreasuriesTable",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["treasuriesTransactionsData", "treasuryData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
