export default class UsersPrivilegeEmployeesMovementMedai {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeesMovementMediaView = false;
    this.employeesMovementMediaAdd = false;
    this.employeesMovementMediaUpdate = false;
    this.employeesMovementMediaArchive = false;
    this.employeesMovementMediaRestore = false;
    this.employeesMovementMediaDelete = false;
  }
  fillData(data) {
    this.employeesMovementMediaView = data.employeesMovementMediaView;
    this.employeesMovementMediaAdd = data.employeesMovementMediaAdd;
    this.employeesMovementMediaUpdate = data.employeesMovementMediaUpdate;
    this.employeesMovementMediaArchive = data.employeesMovementMediaArchive;
    this.employeesMovementMediaRestore = data.employeesMovementMediaRestore;
    this.employeesMovementMediaDelete = data.employeesMovementMediaDelete;
  }
}
