<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addRequestServicesReservation"
    >
      <div class="my-card">
        <div class="row">
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.generalSpecialtyToken"
              :options="generalSpecialtyTokenOptions"
              @change="changeGeneralSpecialty()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/general-specialities.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.specialSpecialtyToken"
              :options="specialSpecialtyTokenOptions"
              @change="changeSpecialSpecialty()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/special-spcialities.svg" />
          </div>

          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.serviceTypeToken"
              :options="serviceTypeTokenOptions"
              @change="changeServiceType()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/services-types.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.serviceToken"
              :options="serviceTokenOptions"
              @change="changeService()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/services.svg" />
          </div>

          <DataLabelGroup
            :className="'col-md-4'"
            :value="servicesReservationData.servicePrice"
            :title="$t('servicePrice')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-md-4'"
            :value="servicesReservationData.serviceTimeWithMinutes"
            :title="$t('serviceTimeWithMinutes')"
            :imgName="'minutes.svg'"
          />
          <DataLabelGroup
            :className="'col-md-4'"
            :value="servicesReservationData.maxDiscountPercentage"
            :title="$t('maxDiscountPercentage')"
            :imgName="'percentage.svg'"
          />

          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.branchToken"
              :options="branchTokenOptions"
              @change="filterOfficesTimes()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/branches.svg" />
          </div>
          <div class="form-label-group col-md-6">
            <b-form-select
              v-model="servicesReservationData.dayToken"
              :options="dayTokenOptions"
              @change="filterOfficesTimes()"
              class="form-control"
            ></b-form-select>
            <img src="@/assets/images/days.svg" />
          </div>
        </div>
      </div>

      <b-button v-b-toggle.reservationTimes class="btn btn-lg btn-collapse">
        {{ $t("reservationTimes") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="reservationTimes">
        <div class="my-card">
          <CustomRadio
            :className="'col-md-12'"
            :id="'officeTimeToken'"
            :value="servicesReservationData.officeTimeToken"
            :title="$t('selectReservationTime')"
            :options="officeTimeTokenOptions"
            v-on:changeValue="servicesReservationData.officeTimeToken = $event"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'reservationRequestNotes'"
            :value="servicesReservationData.reservationRequestNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="
              servicesReservationData.reservationRequestNotes = $event
            "
          />
        </div>
      </b-collapse>

      <b-button v-b-toggle.paymentData class="btn btn-lg btn-collapse">
        {{ $t("paymentData") }}
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </b-button>
      <b-collapse id="paymentData">
        <div class="my-card">
          <div class="row">
            <DatePicker
              :className="'col-md-6 col-lg-4'"
              :id="'reservationDate'"
              :value="servicesReservationData.reservationDate"
              v-on:changeValue="
                servicesReservationData.reservationDate = $event
              "
              :title="$t('reservationDate')"
              :language="language"
            />
            <div class="form-label-group col-md-6 col-lg-4">
              <b-form-select
                v-model="servicesReservationData.feesTypeToken"
                :options="feesTypeTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/type.svg" />
            </div>
            <CustomInputFloat
              :className="'col-md-6 col-lg-4'"
              :id="'feesTypeValue'"
              :value="servicesReservationData.feesTypeValue"
              :title="$t('feesValue')"
              :imgName="'money.svg'"
              v-on:changeValue="servicesReservationData.feesTypeValue = $event"
            />

            <!-- <div class="form-label-group col-md-6">
              <b-form-select
                v-model="servicesReservationData.clientToken"
                :options="clientTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/clients.svg" />
            </div> -->
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'clientToken'"
              :value="servicesReservationData.clientToken"
              :options="clientTokenOptions"
              v-on:changeValue="servicesReservationData.clientToken = $event"
              :title="$t('selectClient')"
              :imgName="'clients.svg'"
            />
            <div class="form-label-group col-md-6">
              <b-form-select
                v-model="servicesReservationData.offerCode"
                :options="offerCodeOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/code.svg" />
            </div>

            <div class="form-label-group col-md-6">
              <b-form-select
                v-model="
                  servicesReservationData.servicesReservationPayData
                    .methodPaidToken
                "
                :options="methodPaidTokenOptions"
                class="form-control"
              ></b-form-select>
              <img src="@/assets/images/payment-method.svg" />
            </div>
            <CustomInputFloat
              :className="'col-md-6'"
              :id="'methodPaidValue'"
              :value="
                servicesReservationData.servicesReservationPayData
                  .methodPaidValue
              "
              :title="$t('paidMoney')"
              :imgName="'money.svg'"
              v-on:changeValue="
                servicesReservationData.servicesReservationPayData.methodPaidValue =
                  $event
              "
            />

            <TextArea
              :className="'col-md-12'"
              :id="'payNote'"
              :value="
                servicesReservationData.servicesReservationPayData.payNote
              "
              :title="$t('notes')"
              :imgName="'notes.svg'"
              v-on:changeValue="
                servicesReservationData.servicesReservationPayData.payNote =
                  $event
              "
            />
          </div>
        </div>
      </b-collapse>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/services-reservations" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
// import axios from "axios";
import DatePicker from "@/components/general/DatePicker.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import CustomRadio from "@/components/general/CustomRadio.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import Branch from "@/models/branches/Branch";
import Client from "@/models/clients/Client";
import GeneralSpecialty from "@/models/settings/settingsOther/generalSpecialties/GeneralSpecialty";
import SpecialSpecialty from "@/models/settings/settingsOther/specialSpecialties/SpecialSpecialty";
import ServicesType from "@/models/settings/settingsOther/servicesTypes/ServicesType";
import Service from "@/models/settings/settingsOther/services/Service";
import MethodPaid from "@/models/settings/settingsOther/methodPaids/MethodPaid";
import OfficeTime from "@/models/settings/settingsBranch/officesTimes/OfficeTime";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ServicesReservationForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    DatePicker,
    CustomInputFloat,
    CustomRadio,
    CustomSelectBox,
    TextArea,
    DataLabelGroup,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      clientData: new Client(),
      clientTokenOptions: [],
      generalSpecialtyData: new GeneralSpecialty(),
      generalSpecialtyTokenOptions: [],
      specialSpecialtyData: new SpecialSpecialty(),
      specialSpecialtyTokenOptions: [],
      servicesTypeData: new ServicesType(),
      serviceTypeTokenOptions: [],
      serviceData: new Service(),
      serviceTokenOptions: [],
      branchData: new Branch(),
      branchTokenOptions: [],
      officeTimeData: new OfficeTime(),
      officeTimeTokenOptions: [],
      offerCodeOptions: [],
      feesTypeTokenOptions: [],
      methodPaidData: new MethodPaid(),
      methodPaidTokenOptions: [],
      dayTokenOptions: [],
    };
  },
  props: ["servicesReservationData", "submitName"],
  methods: {
    async addRequestServicesReservation() {
      this.$emit("addRequestServicesReservation");
    },
    async changeGeneralSpecialty() {
      this.servicesReservationData.specialSpecialtyToken = "";
      this.servicesReservationData.serviceToken = "";
      this.servicesReservationData.servicePrice = "";
      this.servicesReservationData.serviceTimeWithMinutes = "";
      this.servicesReservationData.maxDiscountPercentage = "";
      this.getDialogOfSpecialSpecialties();
      this.getDialogOfServices();
      this.getDialogOfOfficesTimes();
    },
    async changeSpecialSpecialty() {
      this.servicesReservationData.serviceToken = "";
      this.servicesReservationData.servicePrice = "";
      this.servicesReservationData.serviceTimeWithMinutes = "";
      this.servicesReservationData.maxDiscountPercentage = "";
      this.getDialogOfServices();
      this.getDialogOfOfficesTimes();
    },
    async changeServiceType() {
      this.servicesReservationData.serviceToken = "";
      this.servicesReservationData.servicePrice = "";
      this.servicesReservationData.serviceTimeWithMinutes = "";
      this.servicesReservationData.maxDiscountPercentage = "";
      this.getDialogOfServices();
    },
    async changeService() {
      this.serviceData.serviceToken = this.servicesReservationData.serviceToken;
      try {
        let response = await this.serviceData.getServiceDetails(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.serviceData;
        if (response.data.status == STATUS.SUCCESS) {
          this.servicesReservationData.servicePrice = itemsData.servicePrice;
          this.servicesReservationData.serviceTimeWithMinutes =
            itemsData.serviceTimeWithMinutes;
          this.servicesReservationData.maxDiscountPercentage =
            itemsData.maxDiscountPercentage;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.servicesReservationData.servicePrice = "";
          this.servicesReservationData.serviceTimeWithMinutes = "";
          this.servicesReservationData.maxDiscountPercentage = "";
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.servicesReservationData.servicePrice = "";
        this.servicesReservationData.serviceTimeWithMinutes = "";
        this.servicesReservationData.maxDiscountPercentage = "";
      }
    },
    async filterOfficesTimes() {
      this.servicesReservationData.officeTimeToken = "";
      this.getDialogOfOfficesTimes();
    },
    async getDialogOfGeneralSpecialties() {
      this.isLoading = true;
      this.generalSpecialtyTokenOptions = [];
      this.generalSpecialtyTokenOptions.push({
        value: "",
        text: this.$t("selectGeneralSpecialty"),
      });
      try {
        let response =
          await this.generalSpecialtyData.getDialogOfGeneralSpecialties(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.generalSpecialtyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfSpecialSpecialties() {
      this.isLoading = true;
      this.specialSpecialtyTokenOptions = [];
      this.specialSpecialtyTokenOptions.push({
        value: "",
        text: this.$t("selectSpecialSpecialty"),
      });
      try {
        let response = await axios.get(
          `/SpecialSpecialties/GetDialogOfSpecialSpecialties?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&filterStatus=true&generalSpecialtyToken=${this.servicesReservationData.generalSpecialtyToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.specialSpecialtyTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfServicesTypes() {
      this.isLoading = true;
      this.serviceTypeTokenOptions = [];
      this.serviceTypeTokenOptions.push({
        value: "",
        text: this.$t("selectServiceType"),
      });
      try {
        let response = await this.servicesTypeData.getDialogOfServicesTypes(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.serviceTypeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfServices() {
      this.isLoading = true;
      this.serviceTokenOptions = [];
      this.serviceTokenOptions.push({
        value: "",
        text: this.$t("selectService"),
      });
      try {
        let response = await axios.get(
          `/Services/GetAllServices?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}&paginationStatus=false&filterStatus=true&generalSpecialtyToken=${this.servicesReservationData.generalSpecialtyToken}&specialSpecialtyToken=${this.servicesReservationData.specialSpecialtyToken}&serviceTypeToken=${this.servicesReservationData.serviceTypeToken}`
        );
        const itemsData = response.data.servicesPagination.servicesData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.serviceTokenOptions.push({
              value: itemsData[item]["serviceToken"],
              text: itemsData[item]["serviceNameCurrent"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfOfficesTimes() {
      this.isLoading = true;
      this.officeTimeData.dayToken = this.servicesReservationData.dayToken;
      this.officeTimeData.branchToken =
        this.servicesReservationData.branchToken;
      this.officeTimeData.generalSpecialtyToken =
        this.servicesReservationData.generalSpecialtyToken;
      this.officeTimeData.specialSpecialtyToken =
        this.servicesReservationData.specialSpecialtyToken;
      this.officeTimeTokenOptions = [];
      try {
        let response = await this.officeTimeData.getDialogOfOfficesTimes(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.officeTimeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.officeTimeTokenOptions.push({
            value: "",
            text: response.data.msg,
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.officeTimeTokenOptions.push({
          value: "",
          text: this.$t("notFound"),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfDays() {
      this.isLoading = true;
      this.dayTokenOptions = [];
      this.dayTokenOptions.push({
        value: "",
        text: this.$t("selectDay"),
      });
      let days = this.$store.getters.userData.constantLists.listDaysOfWeekData;
      for (let item in days) {
        this.dayTokenOptions.push({
          value: days[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            days[item]["itemNameAr"],
            days[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("selectBranch"),
      });
      try {
        let response = await this.branchData.getDialogOfBranches(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.branchTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfClients() {
      this.isLoading = true;
      this.clientTokenOptions = [];
      this.clientTokenOptions.push({
        value: "",
        text: this.$t("selectClient"),
      });
      try {
        let response = await this.clientData.getDialogOfClients(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.clientTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfOffersCodes() {
      this.isLoading = true;
      this.offerCodeOptions = [];
      this.offerCodeOptions.push({
        value: "",
        text: this.$t("selectOfferCode"),
      });
      this.isLoading = false;
    },
    async getDialogOfFeesTypes() {
      this.isLoading = true;
      this.feesTypeTokenOptions = [];
      this.feesTypeTokenOptions.push({
        value: "",
        text: this.$t("selectFeesType"),
      });
      let items = this.$store.getters.userData.constantLists.listFeesType;
      for (let item in items) {
        this.feesTypeTokenOptions.push({
          value: items[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            items[item]["itemNameAr"],
            items[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
    async getDialogOfMethodPaid() {
      this.isLoading = true;
      this.methodPaidTokenOptions = [];
      this.methodPaidTokenOptions.push({
        value: "",
        text: this.$t("selectMethodPaid"),
      });
      try {
        let response = await this.methodPaidData.getDialogOfMethodPaid(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.methodPaidTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfGeneralSpecialties();
    this.getDialogOfSpecialSpecialties();
    this.getDialogOfServicesTypes();
    this.getDialogOfServices();
    this.getDialogOfOfficesTimes();
    this.getDialogOfDays();
    this.getDialogOfBranches();
    this.getDialogOfClients();
    this.getDialogOfOffersCodes();
    this.getDialogOfFeesTypes();
    this.getDialogOfMethodPaid();
  },
};
</script>

<style lang="scss"></style>
